<template>
  <div class="relative">
    <label class="label inline-block">{{ label }}</label>
    <input type="hidden" :name="inputName" :value="colors.hex">
    
    <div class="relative">
      <div
        class="input pl-2 cursor-pointer flex items-center"
        @click.stop="showColorPicker = !showColorPicker"
      >
        <div
          class="w-6 h-6 rounded-lg mr-2 flex items-center justify-center text-white text-xs font-medium"
          :style="{ 
            backgroundColor: colors.hex,
            border: colors.hex.toLowerCase() === '#ffffff' || colors.hex.toLowerCase() === '#fff' ? '1px solid #e5e7eb' : 'none'
          }"
        >
          <span v-if="showText" class="leading-none">Aa</span>
        </div>
        <span>{{ colors.hex }}</span>
      </div>

      <div 
        v-if="showColorPicker" 
        class="absolute z-10 mt-2 rounded-xl shadow-lg overflow-hidden"
        v-click-outside="() => showColorPicker = false"
      >
        <chrome-picker 
          :value="colors"
          @input="updateColor"
        />
      </div>
    </div>
    <a
      href="#"
      @click.prevent="resetColor"
      class="absolute right-0 bottom-0 mb-3 mr-3 text-sm leading-tight"
    >Reset</a>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  props: {
    currentColor: {
      type: String,
      default: "#2E3F51"
    },
    inputName: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: "Color"
    },
    showText: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      showColorPicker: false,
      colors: {
        hex: this.currentColor,
      }
    };
  },

  components: {
    'chrome-picker': Chrome
  },

  methods: {
    updateColor(colors) {
      this.colors = colors;
      this.$emit('input', colors.hex);
    },

    resetColor() {
      const defaultColor = "#2E3F51";
      this.colors = {
        hex: defaultColor
      };
      this.$emit('input', defaultColor);
    }
  },
};
</script>

<style scoped></style>
