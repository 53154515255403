<template>
  <div
    class="labels__item"
    key="new_label"
    :class="{
      'labels__item--editing': isBeingEdited,
      'labels__item--loading': isLoading
    }"
    style="--label-color: #e4e4e4;"
  >
    <transition name="switch" appear mode="out-in" @after-enter="focusInput()">
      <a
        href="#"
        class="flex w-full"
        v-if="!isBeingEdited"
        key="new_label_inside"
        @click.prevent="startEditing()"
      >
        <span class="labels__item__name nowrap w-full">Add a new label</span>
        <div class="labels__item__controls">
          <span class="labels__item__controls__item">
            <simple-svg :filepath="plusIcon" :width="'20px'" :height="'20px'" />
          </span>
        </div>
      </a>

      <div class="labels__item__form" key="new_label_form" v-else>
        <input
          type="text"
          class="input"
          v-model="newName"
          @keydown.enter.prevent="handleSave()"
          placeholder="New label name"
          ref="input"
        />

        <v-swatches
          v-model="newColor"
          :swatches="swatches"
          show-fallback
          fallback-input-class="input"
        >
          <div
            slot="trigger"
            class="w-8 h-8 rounded-lg flex items-end justify-end"
            :style="{ backgroundColor: newColor }"
          >
            <svg
              class="w-4 h-4 mr-1 mb-1 text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </v-swatches>

        <div class="labels__item__form__controls">
          <span class="labels__item__controls__block">
            <a
              href="#"
              @click.prevent="handleClose()"
              class="mr-4 text-neutral-700"
              >Discard</a
            >
            <button
              type="button"
              class="btn text-sm px-3 py-1"
              @click="handleSave()"
            >
              Save label
            </button>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import plusIcon from "../../images/icons/plus_alt.svg";

import VSwatches from "vue-swatches";

export default {
  props: [],
  data: function() {
    return {
      isBeingEdited: false,
      isLoading: false,
      newName: "",
      newColor: "#828282",
      plusIcon,
      swatches: [
        "#2D80FA",
        "#18BFFF",
        "#1ECFC7",
        "#FF08C3",
        "#F92B60",
        "#FF702C",
        "#FDB402",
        "#20C933",
        "#8B46FF",
        "#666666"
      ]
    };
  },

  components: {
    plusIcon,
    VSwatches
  },

  methods: {
    startEditing(e) {
      this.isBeingEdited = true;
    },

    focusInput() {
      if (this.isBeingEdited) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },

    handleClose() {
      this.isBeingEdited = false;
      this.newName = "";
      this.newColor = "#828282";
    },

    handleSave() {
      this.createNewLabel();
    },

    createNewLabel() {
      this.isLoading = true;
      let params = new FormData();

      params.append("label[name]", this.newName);
      params.append("label[color]", this.newColor);

      Rails.ajax({
        url: "/updates/labels",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.handleClose();
          this.$emit("newLabel", data);
          this.isLoading = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.switch-enter,
.switch-leave-to {
  position: absolute;
  opacity: 0;
  transform: translateY(-10px);
}

.switch-enter-to,
.switch-leave {
  position: relative;
  opacity: 1;
  transform: translateY(0);
}

.switch-enter-active,
.switch-leave-active {
  transition: opacity, transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.drawer .labels__item__form__controls {
  justify-content: flex-end;
}

.drawer .labels__item {
  margin-top: 1rem;
}
.nowrap{
  white-space: nowrap;
}
</style>
