<template>
  <div>
    <span
      v-tippy="{ placement: 'top-end', arrow : true, arrowType : 'default', animation : 'scale', hideOnClick : 'false'}"
      :title="clicked ? 'Link Copied!':'Click to copy'"
    >
      <div class="flex flex-row" @click="copyUrl()"  v-on:mouseleave="mouseleave" >
        <div class="bg-gray-100 hover:bg-gray-200 ml-1 mr-1 py-1 pr-1 pl-2 rounded-lg cursor-pointer changeFill flex flex-row items-center whitespace-nowrap">
          <svg
            class="w-4 h-4 changeFill"
            fill=" #969696"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="text-gray-500 pr-2 pl-1">
          Copy URL
          </span> 
        </div>
      </div>
    </span>
  </div>
</template>

<script>
  export default {
    props: [
      "slug",
    ],
    data: function () {
      return {
        clicked: false
      };
    },
    methods: {
      mouseleave(){
        this.clicked = false;
      },
      copyUrl () {
        this.clicked = true;
        // setTimeout(() => {
        //   console.log("set time out")
        //   this.clicked = false;
        // }, 1000);
        navigator.clipboard.writeText(window.location.href+"/"+this.slug);
      },
    }
  }

</script>
<style scopped>
.changeFill:hover svg{
  fill: #656565;
}
</style>
