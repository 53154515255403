<template>
  <div>
    <div class="w-full">
      <div v-if="ideaSuccessMessage.length > 0">
        <p class="alert alert--good mt-0 mb-6">
          {{ ideaSuccessMessage }}.
          <a class="font-bold ml-auto" :href="newIdeaUrl" style="color: #087743">Open idea</a>
        </p>
      </div>
      <p v-if="ideaErrorMessage.length > 0" class="alert alert--bad">
        {{ ideaErrorMessage }}
      </p>
    </div>
    <div class="flex md:flex-row flex-col justify-between" v-if="isRoadmap">
      <div class="flex flex-col sm:flex-row text-sm" id="roadmap-dropdown">
        <SelectOneDropdown
          :name="$t('Order')"
          :values="sortOptions"
          :currentValue="sort"
          @valueChange="changeSort"
          class="mb-2 sm:mb-0 sm:mr-2 order-dropdown-roadmap margin"
          id="order-dropdown-roadmap"
        >
          <svg
            slot="icon"
            class="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
            ></path>
          </svg>
        </SelectOneDropdown>
        <SelectOneDropdown
          :name="$t('Boards')"
          v-if="boardsOptions && boardsOptions.length"
          :values="boardsOptions"
          :currentValue="boardSort"
          @valueChange="changeBoard"
          class="mb-2 sm:mb-0 sm:mr-2 board-dropdown-roadmap margin"
          id="board-dropdown-roadmap"
        >
        </SelectOneDropdown>
        <SelectMultipleDropdown
          v-if="allTags && allTags.length && currentProjectAdmin"
          :all-tags="allTags"
          :selected-tags="selectedTags"
          @tagsChange="changeTag"
          class="mb-2 sm:mb-0 sm:mr-2 tags-dropdown-roadmap margin"
          id="tags-dropdown-roadmap"
        >
          <simple-svg
            slot="icon"
            :filepath="tagsIcon"
            :width="'16px'"
            :height="'16px'"
          />
        </SelectMultipleDropdown>

      </div>
      <div class="flex flex-col sm:flex-row margin-top text-sm" id="roadmap-statuses">
        <div class="mb-2 ml-2 w-full margin-left btn-margin whitespace-nowrap" id="customize-edit-btn">
          <customize-status
            v-if="currentProjectAdmin"
            :labels="posts_status"
            :project-id="pId"
            button_status="true"
            :status-ids="statusId"
            :status-name="statusName"
            :selected-status="{ id: statusId[0] }"
            id="customize-edit-status"
          >
          </customize-status>
        </div>
        <div class="mb-2 ml-2 w-full margin-left whitespace-nowrap" id="customize-roadmap-btn">
          <customize-roadmap
            v-if="currentProjectAdmin"
            :post-status="posts_status"
            :project-id="pId"
            button_status="true"
            :status-ids="statusId"
            :status-name="statusName"
            id="customize-roadmap-status"
          >
          </customize-roadmap>
        </div>
        <button
          v-if="currentProjectAdmin"
          class="
            btn-height
            w-full
            margin-left
            ml-2
            md:mb-2
            bg-project-500 
            btn-size
            focus:outline-none
            shadow-sm
            text-white
            text-sm
            rounded-lg
            px-3
            py-1
            flex
            items-center
            focus:to-white focus:border-neutral-400
            whitespace-nowrap
          "
          @click="displayNewIdeaModal = true"
        >
          <img class="mr-2" src="../../assets/images/cross-white.svg" />
          <span class="font-semibold">Add new idea</span>
        </button>
      </div>
    </div>

    <NewIdeaModal 
      v-if="displayNewIdeaModal" 
      v-model="displayNewIdeaModal" 
      :title="$t('Add new idea')" 
      :allStatuses="postStatus" 
      :allBoards="sortedBoardsNames" 
      :current-user="currentUser" 
      :idea-creation-url="ideaCreationUrl" 
      :project-color="projectColor"
      :project-private="projectPrivate"
      :isAdmin="currentProjectAdmin"
      :user-signed-in="currentUser" 
      :currentProjectUsersUrl="currentProjectUsersUrl"
      @updateRoadmap="updateRoadmap" 
    />
    
    <div class="relative" id="roadmap-column-container">
      <div v-if="!isLoading" class="flex flex-col fade-up-in mb-8 mt-4 pb-3 items-start scroll_bar lg:flex-row" id="roadmap-columns">
        <roadmap-column
          :project-id="pId"
          v-for="(status,index) in filterStatusPosts"
          :status="status"
          :original-posts="filteredPosts"
          :current-user="currentUser"
          :current-project-admin="currentProjectAdmin"
          :loading="isLoading"
          :allow-anonymous="allowAnonymous"
          :key="status.id"
          :status-name="statusName"
          :class="filterStatusPosts.length-1 == index ? '' : 'mr-8'"
          :id="'roadmap-column-status-'+status.id"
        ></roadmap-column>
      </div>
      <div v-else class="flex justify-center mt-4">
        <div class="lds-ripple text-project-500"><div></div><div></div></div>
      </div>
    </div>
  </div>
</template>

<script>

import SelectOneDropdown from "../components/select_one_dropdown";
import SelectMultipleDropdown from "../components/select_multiple_dropdown";
import tagsIcon from "images/tags.svg";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import EventBus from "event_bus";
import NewIdeaModal from "./new_idea_modal"


export default {
  props: ["current-user", "current-project-admin", "changelog-active", "boards-names", "tag-names", "allow-anonymous", "post-status", "p-id", "status-ids", "status-name", "all-users", "idea-creation-url", "project-color","project-private", "current-project-users-url"],
  components: { SelectOneDropdown,
    SelectMultipleDropdown, NewIdeaModal },
  data: function () {
    return {
      isLoading: false,
      allPosts: [],
      tagsIcon,
      sortOptions: [
        { name: "trend_score", human_name: this.$root.$t("Trending") },
        { name: "total_upvotes", human_name: this.$root.$t("Most votes" )},
        { name: "created_at", human_name: this.$root.$t("Recent") },
        { name: "comments_count", human_name: this.$root.$t("Most discussed") },
      ],
      sort: "trend_score",
      boardSort: 'all',
      tagSort: '',
      allTags: [],
      selectedTags: [],
      filter: "not_done",
      posts_status: this.postStatus,
      statusId: this.statusIds,
      displayNewIdeaModal: false,
      newIdeaUrl: "",
      ideaSuccessMessage: "",
      ideaErrorMessage: "",
    };
  },
  computed:{
    boardsOptions() {
    if (!this.boardsNames) return [];
    let result = this.boardsNames.map(board => {
      return {
        name: board.board_id,
        human_name: board.board_name,
        order_number: board.order_number
      };
    })

    result = result.sort((a, b) => {
      if (a.order_number === null && b.order_number === null) {
        return 0;
      }
      if (a.order_number === null) {
        return 1;
      }
      if (b.order_number === null) {
        return -1;
      }
      return a.order_number - b.order_number;
    });

    result.unshift({ name: "all", human_name: this.$root.$t("All boards") });

    return result;
    },
    sortedBoardsNames() {
      return this.boardsNames.sort((a, b) => {
        if (a.order_number === null && b.order_number === null) {
          return 0;
        }
        if (a.order_number === null) {
          return 1;
        }
        if (b.order_number === null) {
          return -1;
        }
        return a.order_number - b.order_number;
      });
    },
    tagsOptions () {
      return this.tagNames.map ( name => {
        return {
          name: name,
          human_name: name
        }
      })
    },
    filteredPosts () {
      let result = this.allPosts
      result = this.filterTaggedPosts(result)
      result = this.sortedAndFilteredPosts(result)
      return result
    },
    isRoadmap(){
      var current_url = new URL(window.location.href);
      return current_url.pathname === "/roadmap"
    },
    filterStatusPosts () {
      const result = this.posts_status.filter(status => status.name !== this.statusName.not_set)
      const filterStatus = this.statusId.map(id => {
        return result.find(status => status.id === id);
      })
      return filterStatus.filter(status => status.hide_roadmap == false)
    }
  },
  mounted() {
    this.isLoading = true;
    this.boardSort = "all";
    this.tagSort = this.tagNames ? this.tagNames[0] : '';
    var all_tags = this.tagNames ? this.tagNames.map(tag => tag.trim()) : [];
    this.allTags =  [...new Set(all_tags)];

    var current_url = new URL(window.location.href);
    var tags = current_url.searchParams.get("tags");
    var board_id= current_url.searchParams.get("board_id");
    var sort = current_url.searchParams.get("sort");
    if (board_id) {
      // add tags from URL to selectedTags
      this.boardSort = board_id=="all" ? "all" : board_id;
    }
    if (tags) {
      // add tags from URL to selectedTags
      this.selectedTags = tags.split(",");
    }
    if (sort) {
      this.sort = sort;
    }

    this.fetchPosts()
    
    EventBus.$on("status_label", (status) => {
      this.posts_status = status
      this.fetchPosts()
    });
    EventBus.$on("status_ids", (ids) => {
      this.statusId = ids
    });
    EventBus.$on("postRoadmapVotes", (data) => {
      this.allPosts.forEach(post => {
        if(post.id == data.id) {
          post.total_upvotes = data.total;
        }
      })
      if(this.sort == "total_upvotes"){
        this.allPosts = [...this.allPosts];
      }
    });

  },

  methods: {
    fetchPosts(){
      const startTime = Date.now();
      const minLoadingTime = 1500;
      
      const url = this.boardSort == 'all' ? "/posts" : "/posts/?board_id="+this.boardSort
      Rails.ajax({
        url: url,
        type: "GET",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.allPosts = data;
          this.totalVotes(this.allPosts);
          
          const elapsedTime = Date.now() - startTime;
          const remainingTime = Math.max(0, minLoadingTime - elapsedTime);
          
          setTimeout(() => {
            this.isLoading = false;
          }, remainingTime);
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        },
      });
    },
    totalVotes(allPost) {
      for (var i = 0; i < allPost.length; i++){
          allPost[i]['total_upvotes'] = allPost[i].cached_votes_up + allPost[i].manual_upvotes;
        }
      return allPost;
    },
    changeSort(value) {
      this.sort = value;
      var current_url = new URL(window.location.href);
      current_url.searchParams.set("sort", value);
      window.history.replaceState({}, "", current_url);
    },
    changeBoard (value) {
      this.boardSort = value
      let current_url = new URL(window.location.href);
      current_url.searchParams.set("board_id", this.boardSort);
      window.history.replaceState({}, "", current_url);
      this.boardTagAjax()
    },
    changeTag (newTags) {
      this.selectedTags = newTags;
    },
    boardTagAjax () {
      const url = this.boardSort == 'all' ? "/posts" : "/posts/?board_id="+this.boardSort
        Rails.ajax({
        url: url,
        type: "GET",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.allPosts = data;
          this.totalVotes(this.allPosts);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    filterTaggedPosts: function (posts) {

      var tagged = [];
      if (this.selectedTags.includes("untagged")) {
        tagged = posts.filter((post) => {
          return post.tags.length === 0;
        });
      } else {
        tagged = posts.filter((post) => {
          return this.selectedTags.every((tagName) => {
            return post.tags.find((postTag
              ) => {
              return (
                this.parameterize(postTag.name) == this.parameterize(tagName)
              );
            });
          });
        });
      }
      return tagged;
    },
    sortedAndFilteredPosts: function (posts) {

      if (this.sort !== "") {
        var filtered = posts;
        return reverse(sortBy(filtered, this.sort));
      } else {
        return posts;
      }
    },
    parameterize: function (input) {
      return input
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "");
    },
    updateRoadmap(data){
      this.fetchPosts();
      this.ideaSuccessMessage = data.message;
      this.newIdeaUrl = data.url;
    }
  }

};
</script>

<style scoped>
  .scroll_bar::-webkit-scrollbar {
    height: 7px;
    border-radius: 99px;
    background-color: #d4d4d4;
  }

  .scroll_bar::-webkit-scrollbar-thumb {
    background-color: #a3a3a3;
    border-radius: 99px;

  }
  @media only screen and (min-width: 1024px){
    .scroll_bar {
      display: flex;
      overflow-x: auto;
    }
  }
  
  .dark-bg-on-hover:hover{
    filter: brightness(95%);
  }
</style>
<style>
@media only screen and (max-width: 640px)
  {
    .div-margin{
      margin-right: -0.5rem;
      margin-bottom: 0.5rem;
    }
    .div-width{
      width: 50%;
    }
    .margin-left{
      margin-left: 0rem !important;
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 640px)  
  {
    .margin-top{
      margin-top: 0.5rem;
    }
    .btn-height{
      height: 34px;
    }
    .btn-margin{
      margin-left: 0rem !important;
    }
  }
</style>
