<template>
  <div class="max-w-md mx-auto">
    <h3 class="text-center pt-4 font-semibold text-2xl text-neutral-800">
      FeedBear {{ selectedPlanName }}
    </h3>
    <p class="text-center text-neutral-800">
      Great choice! Please enter your payment info below.
    </p>

    <form
      :action="this.action"
      method="post"
      id="payment-form"
      @submit.prevent="handleStripeFormSubmit"
      ref="paymentForm"
      class="mt-6"
      data-rewardful
    >
      <h3 class="text-neutral-800 font-semibold">
        <span>Billing information</span>
      </h3>

      <div class="mt-2">
        <label for="customer_name" class="label">Full name</label>
        <input
          type="text"
          name="customer[name]"
          id="customer_name"
          class="input"
          v-model="customer_name"
          required
        />
      </div>

      <div class="mt-2">
        <label for="customer_address" class="label">Street address</label>
        <input
          type="text"
          name="customer[address]"
          id="customer_address"
          class="input"
          v-model="customer_address_line1"
          required
        />
      </div>

      <div class="mt-2">
        <label for="customer_city" class="label">City</label>
        <input
          type="text"
          name="customer[city]"
          id="customer_city"
          class="input"
          v-model="customer_address_city"
          required
        />
      </div>

      <div class="mt-2 form__field--select--flat">
        <label for="customer_country" class="label">Country</label>
        <div class="relative">
          <select
            name="customer[country]"
            id="customer_country"
            @change="countryChange"
            v-model="customer_address_country"
            ref="countrySelector"
            class="select"
            required
          >
            <option disabled value>Please select one</option>
            <option
              v-for="country in this.countries"
              :key="country.alpha3Code"
              :value="country.name"
            >
              {{ country.name }}
            </option>
          </select>
          <svg
            class="w-6 h-6 absolute top-0 right-0 mt-2 pointer-events-none mr-2 text-neutral-700"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>

      <div class="mt-2">
        <label for="customer_postal_code" class="label">Postal code</label>
        <input
          type="text"
          name="customer[postal_code]"
          id="customer_postal_code"
          class="input"
          v-model="customer_address_postal_code"
          required
        />
      </div>

      <div class="mt-2">
        <label for="customer_discount_code" class="label">Discount code</label>
        <div class="flex">
          <input
            id="customer_discount_code"
            type="text"
            class="input"
            v-model="discountCoupon"
            ref="discount-input"
            autocomplete="off"
          />
          <button
            class="flex border border-solid border-gray-300 ml-2 text-neutral-700 rounded-lg py-2 px-7"
            type="button"
            @click.prevent="checkCoupon"
          >
            <span>Apply</span>
            <svg
              v-if="couponCheckInProgress"
              class="animate-spin h-15 w-5 ml-2"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                fill="#fff"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
        <p v-if="couponResponseSuccess" class="text-green-500 text-sm mt-1">
          Your code has been applied successfully
        </p>
        <p v-if="couponResponseError" class="text-red-500 text-sm mt-1">
          {{ couponResponseError }}
        </p>
      </div>

      <div class="mt-2" v-if="this.is_eu">
        <label for="customer_vat_id" class="label">VAT ID (optional)</label>
        <div class="relative">
          <div class="form__field__loader" v-if="this.is_validating">
            <simple-svg :filepath="Loader" :width="'32px'" :height="'32px'" />
          </div>
          <div class="form__field__status" v-if="this.vat_id_valid">
            <simple-svg
              :filepath="Check"
              :width="'16px'"
              :height="'16px'"
              fill="#37bf64"
            />
          </div>
          <input
            type="text"
            name="customer[vat_id]"
            id="customer_vat_id"
            class="input"
            v-model="vat_id"
            @blur="checkVAT()"
          />
        </div>
        <small class="text-red-500" v-if="this.vat_id_valid === false"
          >This VAT ID is invalid</small
        >
      </div>

      <h3 class="text-neutral-800 font-semibold mt-4">
        <span>Card details</span>
      </h3>

      <div class="mt-2">
        <div class="input py-3" ref="card"></div>
        <small id="card-errors" class="text-red-500" ref="cardErrors"></small>
      </div>

      <div class="mt-4">
        <transition name="component-fade" mode="out-in">
          <button
            type="submit"
            class="btn-main w-full text-center justify-center"
            v-if="!is_processing_payment"
          >
            Complete Payment
          </button>
          <div v-else>
            <simple-svg :filepath="Loader" :width="'32px'" :height="'32px'" />
            <p class="text-center text-neutral-800">
              Stand up and stretch while we process the payment.
            </p>
          </div>
        </transition>

        <p
          class="alert alert--good"
          v-if="customerData && customerData.discount"
        >
          <span>
            This project has an active discount
            <strong>{{ customerData.discount.coupon.name }}</strong> of
            <strong>{{ humanized_discount }} off</strong> applied. Awesome!
          </span>
        </p>

        <p class="text-left mt-4 text-neutral-800">
          <strong>
            We'll charge your card ${{ amountToCharge }}
            <span v-if="is_eu">(VAT included)</span>
          </strong>
          now and each
          <span>{{ selectedPlan.includes("monthly") ? "month" : "year" }}</span>
          thereafter. You can cancel any time, no questions asked.
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from "images/loader.svg";
import Check from "images/check.svg";
import countries_data from "countries.json";

let stripe = undefined,
  elements = undefined,
  card = undefined;

export default {
  props: [
    "public-key",
    "action",
    "validate-vat-url",
    "stripe-customer",
    "plan",
  ],
  data: function () {
    return {
      vat_id: "", // SK1121036510
      vat_id_valid: null,
      is_validating: false,
      countries: [],
      is_eu: false,
      customerData: JSON.parse(this.stripeCustomer),
      customer_name: "",
      customer_address_line1: "",
      customer_address_city: "",
      customer_address_country: "",
      customer_address_postal_code: "",
      is_processing_payment: false,
      selectedPlan: this.plan,
      Loader,
      Check,
      planPrices: {
        lite_monthly: 19,
        lite_yearly: 190,
        startup_monthly: 29,
        startup_yearly: 288,
        business_monthly: 99,
        business_yearly: 984,
        professional_yearly: 1990,
        professional_monthly: 199,
        corporate_50_monthly: 299,
        corporate_100_monthly: 499,
        corporate_50_yearly: 2990,
        corporate_100_yearly: 4990,
        startup_202304_monthly: 49,
        startup_202304_yearly: 490,
        business_202306_monthly: 199,
        business_202306_yearly: 1990,
      },
      discountCoupon: "",
      couponCheckInProgress: false,
      couponResponseSuccess: "",
      couponResponseError: "",
      coupon: {},
    };
  },
  components: {
    Loader,
    Check,
  },
  watch: {
    plan: function (new_val, old_val) {
      this.selectedPlan = new_val;
    },
  },
  computed: {
    humanized_discount: function () {
      var coupon = this.customerData.discount.coupon;

      if (coupon.percent_off) {
        return coupon.percent_off + "%";
      }

      if (coupon.amount_off) {
        return "$" + coupon.amount_off / 100;
      }
    },

    selectedPlanName() {
      let planString = this.selectedPlan.replace('_202304','').replace('_202306', '').split("_");
      return planString.length > 2
        ? `${this.capitalize(planString[0])} ${this.capitalize(
            planString[1]
          )} ${this.capitalize(planString[2])} Plan`
        : `${this.capitalize(planString[0])} ${this.capitalize(
            planString[1]
          )} Plan`;
    },

    amountToCharge: function () {
      var amount = this.planPrices[this.selectedPlan];

      if (!!Object.keys(this.coupon).length) {
        if (this.coupon.percent_off) {
          amount = amount * ((100 - this.coupon.percent_off) / 100);
        }

        if (this.coupon.amount_off) {
          amount = amount - this.coupon.amount_off / 100;
        }
      }

      if (this.is_eu) {
        amount = amount * 1.2;
      }

      return amount.toFixed(2);
    },
  },
  mounted: function () {
    var style = {
      base: {
        fontSize: "16px",
      },
    };

    console.log(this.publicKey);
    
    stripe = Stripe(this.publicKey);
    elements = stripe.elements();
    card = elements.create("card", { style: style, hidePostalCode: true });

    card.mount(this.$refs.card);
    // this.countries = countries_data
    this.countries = this.loadCountries();
    if (this.customerData != null) {
      if (this.customerData.tax_ids.length > 0) {
        this.vat_id = this.customerData.tax_ids[0].data.value;
      }

      this.customer_name = this.customerData.name;

      if (this.customerData.address) {
        this.customer_address_line1 = this.customerData.address.line1;
        this.customer_address_city = this.customerData.address.city;
        this.customer_address_country = this.customerData.address.country;
        this.customer_address_postal_code = this.customerData.address.postal_code;
      }
    }

    Rewardful.Forms.attach();
  },
  methods: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    handleStripeFormSubmit: function () {
      if (this.is_processing_payment) return;
      this.is_processing_payment = true;
      let self = this;
      if (typeof fbq !== "undefined") {
        fbq("track", "InitiateCheckout");
      }
      if (this.checkformValidity()) {
        stripe.createToken(card).then(function (result) {
          if (result.error) {
            self.$refs.cardErrors.textContent = result.error.message;
            self.is_processing_payment = false;
            self.$forceUpdate();
          } else {
            // Send the token to your server.
            self.stripeTokenHandler(result.token);
          }
        });
      }
    },

    checkformValidity: function () {
      let self = this;

      if (self.$refs.paymentForm.checkValidity()) {
        if (self.vat_id.length === 0) {
          return true;
        } else {
          if (self.vat_id_valid) {
            return true;
          }
        }
      }

      return false;
    },

    stripeTokenHandler: function (token) {
      let self = this;
      // Insert the token ID into the form so it gets submitted to the server
      var form = this.$refs.paymentForm;
      var hiddenInput = document.createElement("input");

      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "stripeToken");
      hiddenInput.setAttribute("value", token.id);
      form.appendChild(hiddenInput);

      let stripeData = new FormData(form);
      stripeData.append("customer[is_eu]", self.is_eu);
      stripeData.append("plan", self.selectedPlan);
      if (!!Object.keys(this.coupon).length)
        stripeData.append("customer[coupon]", self.coupon.id);

      Rails.ajax({
        url: this.action,
        type: "POST",
        data: stripeData,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log(data);
          switch (data.status) {
            case "action":
              var paymentIntentSecret =
                data.subscription.latest_invoice.payment_intent.client_secret;

              stripe
                .handleCardPayment(paymentIntentSecret)
                .then(function (result) {
                  if (result.error) {
                    console.log("3DS error", result);
                    self.$refs.cardErrors.textContent = result.error.message;
                    self.$forceUpdate();
                  } else {
                    console.log("True success");
                  }
                });
              break;

            case "error":
              console.log(
                "Payment method declined. Need to collect again and then update."
              );
              self.is_processing_payment = false;
              self.$refs.cardErrors.textContent = data.message;
              break;

            case "ok":
              console.log("Charge successful, subscription started.");

              dataLayer.push({
                event: "purchaseV2",
                paymentValue: this.planPrices[this.selectedPlan],
              });
              this.$emit("payment-ok");
              break;

            default:
              break;
          }
        },
        error: (data, status) => {
          self.is_processing_payment = false;
          console.log("Error: ", +data);
        },
      });
    },

    loadCountries: function () {
      let self = this;
      self.countries = countries_data;
      if (self.customerData != null && self.customerData.address) {
        for (let index = 0; index < self.countries.length; index++) {
          const country = self.countries[index];
          if (country.name === self.customerData.address.country) {
            self.is_eu = country.name == "United Kingdom";
          }
        }
      }
      return countries_data;
    },

    checkVAT: function () {
      let self = this;

      if (self.vat_id.length > 0) {
        self.is_validating = true;

        fetch(self.validateVatUrl + "?vat_id=" + self.vat_id)
          .then(function (response) {
            return response.json();
          })
          .then(function (resultJson) {
            self.is_validating = false;
            self.vat_id_valid = resultJson.valid;
          });
      } else {
        self.vat_id_valid = null;
      }
    },

    countryChange: function (e) {
      let self = this;
      let country = this.countries[e.target.selectedIndex - 1];
      self.is_eu = country.name == "United Kingdom";
    },

    checkCoupon() {
      this.resetCouponFields();
      this.couponCheckInProgress = true;

      let self = this;
      let data = new FormData();

      data.append("coupon_id", this.discountCoupon);

      Rails.ajax({
        url: "/payments/check_coupon_validity",
        type: "POST",
        data: data,
        dataType: "json",
        success: (response) => {
          this.couponCheckInProgress = false;
          this.couponResponseSuccess = response.coupon.valid;
          this.coupon = response.coupon;
          self.$refs["discount-input"].style.borderColor = "#38c172";
          self.$refs["discount-input"].style.color = "#38c172";
        },
        error: (response) => {
          this.couponCheckInProgress = false;
          this.couponResponseError = response.error;
          console.log(response);
        },
      });
    },

    resetCouponFields() {
      this.couponResponseSuccess = "";
      this.couponResponseError = "";
      this.$refs["discount-input"].style.borderColor = "#CBCFD4";
      this.$refs["discount-input"].style.color = "#2d3748";
    },
  },
};
</script>

<style scoped>
.form__field__loader {
  position: absolute;
  width: 32px;
  right: 0.5rem;
  top: 0.3rem;
}

.form__field__status {
  position: absolute;
  width: 16px;
  right: 1rem;
  top: 0.8rem;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}

@-webkit-keyframes autofill {
  0%,100% {
    background: transparent;
  }
}

#customer_discount_code:-webkit-autofill {
  -webkit-animation-delay: 1s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
</style>
