<template>
  <div class="z-[999] relative">
    <Dialog title="Set new password" v-model="password_forgot_dialog_visible">
      <div class="px-4 py-4">
          <div v-if="isSuccess" class="text-center">
            <div class="mb-4">
              <svg class="mx-auto h-12 w-12 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <h3 class="mt-2 text-lg font-medium text-gray-900">Password successfully reset!</h3>
              <p class="mt-1 text-sm text-gray-500">You can now log in with your new password.</p>
            </div>
            <button
              type="button"
              @click.prevent="redirectToLogin"
              class="btn-main w-full flex items-center justify-center min-h-[48px]"
            >
              Go to login
            </button>
          </div>
          <form
            v-else
            id="forgot_password"
            class="form"
            ref="forgotpasswordform"
            @submit.prevent
          >
            <div class="mt-4">
              <label for="user_email" class="label">New password</label>
              <input
                autocomplete="password"
                required="required"
                type="password"
                value=""
                name="user[password]"
                id="forgot_password"
                class="input"
                :disabled="isSubmitting"
              />
            </div>
            <input
              type="hidden"
              name="user[email]"
              :value=this.userEmail
            />
            <div class="mt-4 flex justify-center">
              <button
                type="button"
                @click.prevent="forgotPassword"
                class="btn-main w-full flex items-center justify-center gap-2 min-h-[48px]"
                :disabled="isSubmitting"
              >
                <svg v-if="isSubmitting" class="spinner" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 6l0 -3"></path>
                  <path d="M16.25 7.75l2.15 -2.15"></path>
                  <path d="M18 12l3 0"></path>
                  <path d="M16.25 16.25l2.15 2.15"></path>
                  <path d="M12 18l0 3"></path>
                  <path d="M7.75 16.25l-2.15 2.15"></path>
                  <path d="M6 12l-3 0"></path>
                  <path d="M7.75 7.75l-2.15 -2.15"></path>
                </svg>
                {{ isSubmitting ? 'Setting password...' : 'Set password' }}
              </button>
            </div>
          </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "./dialog";

export default {
  props: [
    "button-class",
    "time-now",
    "email",
  ],
  data() {
    return {
      password: true,
      login_error_msg: "",
      password_forgot_dialog_visible: true,
      userEmail: '',
      isSubmitting: false,
      isSuccess: false,
    };
  },
  mounted() {
    this.userEmail = this.email ? this.email : ''
  },
  components: {
    Dialog,
  },

  methods: {
    async forgotPassword() {
      this.isSubmitting = true;
      const data = new FormData(this.$refs.forgotpasswordform);
      
      try {
        const response = await fetch('/users/forgot/password', {
          method: 'POST',
          body: data,
          headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
          }
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }
        
        this.isSuccess = true;
      } catch (error) {
        this.login_error_msg = error.message;
      } finally {
        this.isSubmitting = false;
      }
    },

    redirectToLogin() {
      this.password_forgot_dialog_visible = false;
      const login = document.getElementById("auth-login-btn");
      login.click();
    }
  },
  watch: {
    password_forgot_dialog_visible (newVal) {
      if (!newVal && window.location.search.includes("view_popup")) {
        const newUrl = window.location.pathname + window.location.search.replace(/[?&]view_popup=[^&]*(&|$)/g, '');
        window.history.replaceState({}, '', newUrl);
      }
    }
  }
};
</script>

<style scoped>
.btn-soc {
  @apply p-2 shadow-md rounded-lg border border-neutral-100 transition duration-150 ease-in-out;
}

.btn-soc:hover {
  @apply shadow-lg;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
