<template>
  <div class="">
    <div id="labels-div" class="flex items-center justify-between">
      <h4 id="id1" class="text-neutral-800 font-semibold">Labels</h4>
      <LabelsSidebar
        :labels="allLabels"
        @createdLabel="addLabel($event)"
        @updatedLabel="updateLabel($event)"
        @destroyedLabel="removeLabel($event)"
      ></LabelsSidebar>
      <h4 id="id2" class="text-neutral-800 font-semibold">Labels</h4>
    </div>

    <div class="mt-2 flex flex-col items-start">
      <input type="hidden" name="changelog_entry[label_ids][]" value="" />

      <div
        id="checkbox-div"
        class="changelog-label mb-2 hover:bg-white rounded-lg flex items-center"
        :style="'--label-color: ' + label.color + ';'"
        v-for="label in allLabels"
        :key="label.id"
      >
        <input
          type="checkbox"
          :value="label.id"
          :checked="label.checked"
          name="changelog_entry[label_ids][]"
          :id="'changelog_entry_label_ids_' + label.id"
          class="custom-checkbox"
        />
        <label
          :for="'changelog_entry_label_ids_' + label.id"
          class="cursor-pointer relative z-10 select-none py-2 px-2"
        >
          {{ label.name }}</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import LabelsSidebar from "./index";

export default {
  props: ["labels"],
  data: function() {
    return {
      allLabels: this.labels
    };
  },

  components: {
    LabelsSidebar
  },

  methods: {
    addLabel(label) {
      this.allLabels.push(label);
    },

    removeLabel(label) {
      this.allLabels.splice(this.allLabels.indexOf(label), 1);
    },

    updateLabel(label) {
      // this.$emit("updatedLabel", label);
    }
  }
};
</script>

<style scoped lang="scss">
.custom-checkbox + label::before {
  @apply w-4 h-4 mb-0 mr-1;
}

.custom-checkbox:checked + label::before {
  background-color: var(--label-color);
  border-color: var(--label-color);
}

#id2{
  display: none;
}

@media only screen and (max-width: 768px){
  #labels-div{
    display: block;

    #id2{
      display: block;
      margin-top: 15px;
    }
    #id1{
      display: none;
    }
  }
}

</style>
