<template>
  <div>
    <div v-if="isMobileScreen && !showForm">
      <button
        class="btn-hide w-full text-center block mt-4"
        @click="hideButton"
      >
        {{
          this.showForm == true
            ? "Hide Form"
            : $t(current_board.form_button_text)
        }}
      </button>
    </div>
    <transition name="slideDown">
      <div v-if="showForm">
        <form
          enctype="multipart/form-data"
          @submit.prevent="fetchForm()"
          accept-charset="UTF-8"
          method="post"
          class="mt-4"
          id="new-idea-form"
        >
          <slot name="honey"></slot>
          <div>
            <AuthorSelect
              v-if="currentProjectAdmin"
              :label="$t('Author Name')"
              :options="allUsers"
              :current-user="currentUser"
              :show-options="showOptions"
              :board-post-author-select="boardPostAuthorSelect"
              v-model="postAuthor"
              @showAuthorEmailField="showAuthorEmailField"
              @hideAuthorEmailField="hideAuthorEmailField"
              @addSelectedAuthor="addSelectedAuthor"
              @removeSelectedAuthor="removeSelectedAuthor"
              @showDropdown="showDropdown"
            />
          </div>
          <section v-if="authorEmailField">
            <div class="mt-3">
              <label class="font-semibold text-sm leading-3 text-gray-700"
                >{{ $t("Author email") }}
              </label>
              <input
                id="author_email"
                placeholder=""
                v-model="authorEmail"
                type="email"
                name="post[email]"
                class="input rounded mt-1"
                autocomplete="off"
              />
            </div>

            <div
              class="mt-3 text-neutral-700 text-xs text-width flex flex-row"
              v-if="!projectPrivate"
            >
              <input
                type="checkbox"
                value="1"
                name="post[notify_user]"
                id="agree_checkbox"
                v-model="agreeCheckbox"
                style="height: 12px; margin-top: 2px"
              />
              <div class="ml-1">
                {{
                  $t("Notify the user about updates and comments to this idea")
                }}
              </div>
            </div>
          </section>
          <input name="utf8" type="hidden" value="✓" />
          <input
            type="hidden"
            name="authenticity_token"
            :value="this.form_token"
          />

          <p v-if="this.draft_info_shown" class="draft-message text-dblue-500">
            We saved an unpublished draft for you.
            <a href="#" @click.prevent="deleteDraftAndResetFields()">Delete</a>
          </p>

          <div
            class="mt-3"
            v-if="!user_signed_in && this.projectSsoOnly !== 'true'"
          >
            <label class="email">repeat your email address (required)</label>
            <input
              type="email"
              class="email"
              name="remark"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              placeholder="name@domain.com"
            />
          </div>

          <div class="mt-3" id="post-title">
            <label class="label">{{ $t(current_board.form_title) }}</label>
            <input
              id="post_title"
              :placeholder="$t(current_board.form_title_placeholder)"
              v-model="new_post_title"
              autocomplete="off"
              required="required"
              type="text"
              name="post[title]"
              class="input"
            />
          </div>
          <div class="mt-3" id="post-content">
            <label class="label">{{ $t(current_board.form_content) }}</label>
            <textarea
              id="post_content"
              :placeholder="$t(current_board.form_content_placeholder)"
              v-model="new_post_content"
              rows="4"
              name="post[content]"
              class="input"
            ></textarea>
          </div>
          <div v-if="error" class="text-red-700 pt-4">
            <p>{{ error }}</p>
          </div>
          <div v-if="emailError" class="text-red-700 pt-4 cursor-pointer">
            <p>
              Email is already in use.
              <a @click="clickLoginBtn()"> Click here </a>
              a to login and add your idea
            </p>
          </div>
          <FileUpload
            name="post[attachments][]"
            class="mt-3"
            :noun="$t('images')"
            upy-id="post-image"
          >
          </FileUpload>
          <div
            class="mt-3 px-2 py-2 bg-gray-50 rounded-t-md"
            v-if="!user_signed_in && this.projectSsoOnly !== 'true'"
            id="post-name"
          >
            <label class="label"
              >{{ $t("Name") }} {{ allow_anonymous_posts ? "" : "*" }}</label
            >
            <input
              id="name"
              :placeholder="$t('Your name')"
              v-model="name"
              autocomplete="off"
              :required="!allow_anonymous_posts"
              type="text"
              name="post[name]"
              class="input"
            />
          </div>
          <div
            class="pt-3 px-2 py-2 bg-gray-50"
            v-if="!user_signed_in && this.projectSsoOnly !== 'true'"
            id="post-email"
          >
            <label class="label"
              >{{ $t("Email") }} {{ allow_anonymous_posts ? "" : "*" }}</label
            >
            <input
              id="email"
              :placeholder="$t('Your email address')"
              v-model="email"
              autocomplete="off"
              :required="!allow_anonymous_posts"
              type="email"
              name="post[email]"
              class="input"
            />
          </div>
          <p
            class="whitespace-pre-line pt-2 text-neutral-800 text-left break-words px-2 py-2 bg-gray-50"
            v-if="formFooterText"
          >
            {{ formFooterText }}
          </p>
          <div
            class="pt-3 text-neutral-700 text-xs text-width flex flex-row px-2 py-2 bg-gray-50"
            :class="{ 'rounded-b-md': !allow_anonymous_posts }"
            v-if="!user_signed_in"
            id="post-agree"
          >
            <input name="user[agree_checkbox]" type="hidden" value="0" />
            <input
              :required="!allow_anonymous_posts"
              type="checkbox"
              value="1"
              name="user[agree_checkbox]"
              id="user_agree_checkbox"
              v-model="agree_checkbox"
              style="height: 12px; margin-top: 2px"
            />
            <div class="ml-1">
              <span>{{ $t("I agree with") }}</span>
              <a
                href="https://www.feedbear.com/privacy-policy"
                title="Privacy Policy"
                target="_blank"
                class="text-neutral-600 underline hover:no-underline hover:text-project-500"
                >{{ $t("Privacy Policy") }}</a
              >
              {{ $t("and") }}
              <a
                href="https://www.feedbear.com/terms"
                title="Terms of Service"
                target="_blank"
                class="text-neutral-600 underline hover:no-underline hover:text-project-500"
                >{{ $t("Terms and Conditions") }}</a
              >
              {{ allow_anonymous_posts ? "" : "*" }}
            </div>
          </div>
          <div
            class="pt-3 text-neutral-700 text-xs px-2 py-2 bg-gray-50 rounded-b-md"
            v-if="allow_anonymous_posts && !user_signed_in"
          >
            Optional. If you provide your email address, we will notify you when
            your feedback receives comments or updates.
          </div>
          <input
            :value="this.timeNow"
            type="hidden"
            name="post[agreed_with_privacy_policy]"
            id="user_agreed_with_privacy_policy"
          />

          <input
            v-model="user_ip_address"
            type="hidden"
            name="post[user_ip_address]"
          />

          <div class="mt-3" id="post-submit-btn">
            <button
              class="btn-main w-full text-center block"
              @click.prevent="ssoPopUp()"
              v-if="
                !user_signed_in &&
                this.projectSsoOnly === 'true' &&
                customWebsiteHref != ''
              "
            >
              {{ $t(current_board.form_button_text) }}
            </button>
            <button
              name="button"
              type="submit"
              class="btn-main w-full text-center block"
              @click="deleteDraft()"
              :disabled="isSubmitting"
              v-else
            >
              <div class="flex items-center justify-center gap-2">
                <svg v-if="isSubmitting" class="spinner" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 6l0 -3"></path>
                  <path d="M16.25 7.75l2.15 -2.15"></path>
                  <path d="M18 12l3 0"></path>
                  <path d="M16.25 16.25l2.15 2.15"></path>
                  <path d="M12 18l0 3"></path>
                  <path d="M7.75 16.25l-2.15 2.15"></path>
                  <path d="M6 12l-3 0"></path>
                  <path d="M7.75 7.75l-2.15 -2.15"></path>
                </svg>
                {{ isSubmitting ? '' : $t(current_board.form_button_text) }}
              </div>
            </button>
          </div>
        </form>
        <Dialog v-model="ssoLoginPopup">
          <div class="px-4 pb-4">
            <div class="text-center font-semibold">Log in to proceed</div>
            <div class="p-4 text-center">
              You must be logged in to submit feedback, <br />click the button
              below to login
            </div>
            <div class="flex justify-center">
              <a
                :href="this.customWebsiteHref"
                @click="ssoAddPost()"
                class="btn-main text-neutral-800 btn-SignSso"
              >
                Sign up / Log in
              </a>
            </div>
          </div>
        </Dialog>
      </div>
    </transition>
  </div>
</template>

<script>
import EventBus from "event_bus";
import Dialog from "../components/dialog";
import PaperclipIcon from "../images/icons/paperclip.svg";
import FileUpload from "../components/file_upload";
import AuthorSelect from "../roadmap/custom_select";
import debounce from "lodash/debounce";
import NewIdeaModal from "../roadmap/new_idea_modal.vue";
import { get } from "http";
import { isLoggedOutOnMobile } from "normalize-range";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NewPost",
  props: [
    "token",
    "action",
    "board",
    "signed_in",
    "search_url",
    "time-now",
    "currentProjectUsersUrl",
    "form-footer-text",
    "project-sso-only",
    "custom-website-href",
    "current-user",
    "current-project-admin",
    "project-private",
    "board-post-author-select",
    "post-status",
    "boards-names",
    "allow-anonymous-posts",
    "is-admin",
  ],
  data: function () {
    return {
      form_action: this.action,
      form_token: this.token,
      current_board: this.board,
      user_signed_in: this.signed_in,
      new_post_title: "",
      new_post_content: "",
      name: "",
      email: "",
      emailError: "",
      draft_info_shown: false,
      PaperclipIcon,
      user_ip_address: "",
      ssoLoginPopup: false,
      agree_checkbox: false,
      error: "",
      postAuthorName: "",
      selectedAuthor: this.currentUser ? this.currentUser.id : "",
      authorEmailField: false,
      agreeCheckbox: false,
      authorEmail: "",
      showOptions: true,
      showForm: false,
      isMobileScreen: false,
      isLoggedOutOnMobile: !this.isAdmin && window.innerWidth <= 480,
      allow_anonymous_posts: !!JSON.parse(this.allowAnonymousPosts),
      isSubmitting: false,
    };
  },
  components: {
    FileUpload,
    Dialog,
    AuthorSelect,
    NewIdeaModal,
  },
  computed: {
    ...mapGetters(["allUsers"]),
    postAuthor: {
      get() {
        return this.postAuthorName;
      },
      set(val) {
        this.postAuthorName = val;
      },
    },
    displayModalBoardForm() {
      return this.isLoggedOutOnMobile;
    },
  },

  async mounted() {
    console.log(this.allow_anonymous_posts);
    if (this.user_signed_in && this.currentProjectAdmin) {
      await this.$store.dispatch(
        "getcurrentProjectUsers",
        this.currentProjectUsersUrl
      );
    }

    if (localStorage.new_post_title) {
      this.new_post_title = localStorage.new_post_title;
      this.draft_info_shown = true;
    }
    if (localStorage.new_post_content) {
      this.new_post_content = localStorage.new_post_content;
      this.draft_info_shown = true;
    }
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.user_ip_address = ip;
      });
    var postData = JSON.parse(window.localStorage.getItem("post"));
    if (postData && postData.current_board_id == this.current_board.id) {
      this.new_post_title = postData.title;
      this.new_post_content = postData.content;
      this.agree_checkbox = postData.agree_checkbox;
      localStorage.removeItem("post");
    }
    this.isMobileScreen = window.innerWidth <= 480 ? true : false;
    this.showForm = !this.isMobileScreen;
  },
  watch: {
    // whenever user types a space, we should load suggestions
    new_post_title: function (term) {
      if (this.new_post_title.length < 4) {
        EventBus.$emit("hideSuggestions");
        localStorage.title = term;
      } else {
        this.debouncedGetSuggestions();
        localStorage.title = term;
      }
    },
    new_post_content: function (term) {
      localStorage.description = term;
    },
  },
  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.name = urlParams.get("name");
    this.email = urlParams.get("email");
    this.showValues();
    this.debouncedGetSuggestions = debounce(this.getSuggestions, 500);
  },
  methods: {
    ...mapActions(["getcurrentProjectUsers"]),
    showValues: function () {
      if (localStorage.title) {
        this.new_post_title = localStorage.title;
      }
      if (localStorage.description) {
        this.new_post_content = localStorage.description;
      }
    },
    hideButton: function () {
      this.showForm = !this.showForm;
    },
    openRegModal: function () {
      EventBus.$emit("openRegModal");
      this.persistDraft();
    },
    persistDraft: function () {
      localStorage.new_post_title = this.new_post_title;
      localStorage.new_post_content = this.new_post_content;
    },
    ssoPopUp() {
      this.ssoLoginPopup = true;
    },
    showAuthorEmailField(postAuthor) {
      this.authorEmailField = true;
    },
    showDropdown(value) {
      this.showOptions = value;
    },
    hideAuthorEmailField() {
      this.authorEmailField = false;
      this.postAuthor = "";
    },

    addSelectedAuthor(authorId) {
      this.selectedAuthor = authorId;
      this.postAuthor = "";
    },

    removeSelectedAuthor() {
      this.selectedAuthor = null;
    },
    ssoAddPost() {
      const post_data = {
        title: this.new_post_title,
        content: this.new_post_content,
        agree_checkbox: this.agree_checkbox,
        current_board_id: this.current_board.id,
      };
      window.localStorage.setItem("post", JSON.stringify(post_data));
      this.ssoLoginPopup = false;
    },
    deleteDraftAndResetFields() {
      this.deleteDraft();
      this.resetFields();
    },
    clickLoginBtn: function () {
      const login = document.getElementById("auth-login-btn");
      login.click();
    },
    fetchForm() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      const formElem = document.getElementById("new-idea-form");
      const formData = new FormData(formElem);
      localStorage.setItem("title", this.new_post_title);
      localStorage.setItem("description", this.new_post_content);

      if (this.user_signed_in && this.currentProjectAdmin) {
        if (this.selectedAuthor) {
          formData.append("post[author]", this.selectedAuthor);
        } else {
          formData.append("post[name]", this.postAuthor);
          if (!this.agreeCheckbox) formData.append("dont_notify_author", true);
        }
      }
      setTimeout(() => {
      Rails.ajax({
        url: this.form_action,
        type: "POST",
        data: formData,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          localStorage.removeItem("title");
          localStorage.removeItem("description");
          this.resetFields();
          this.isSubmitting = false;
        },
        error: (data, status) => {
          if (data.email_used) {
            this.emailError = data.email_used;
          } else {
            this.error = data.error_message;
          }
          this.isSubmitting = false;
        },
        });
      }, 750);
    },
    deleteDraft() {
      localStorage.removeItem("new_post_title");
      localStorage.removeItem("new_post_content");
      this.draft_info_shown = false;
    },

    resetFields() {
      this.new_post_title = "";
      this.new_post_content = "";
    },

    getSuggestions: debounce(function () {
      Rails.ajax({
        url: this.search_url + "?search_term=" + this.new_post_title,
        type: "GET",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if (data.length > 0) {
            EventBus.$emit("showSuggestions", data);
          }
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    }, 500),
  },
};
</script>

<style scoped>
.draft-message {
  position: relative;
  margin-top: 1rem;
  padding: 0.5rem 0.8rem;
  z-index: 1;
}

.draft-message::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  opacity: 0.1;
  pointer-events: none;
  z-index: 0;

  @apply rounded-lg;
}
.btn-SignSso {
  width: 160px;
  color: #fff;
}
.email {
  position: absolute;
  left: -9999px;
}

@media only screen and (max-width: 480px) {
  .slideDown-enter,
  .slideDown-leave-to {
    opacity: 0;
    transform: translateY(-0.5rem);
  }

  .slideDown-enter-to,
  .slideDown-leave {
    opacity: 1;
    transform: translateY(0);
  }

  .slideDown-enter-active,
  .slideDown-leave-active {
    @apply transition duration-150 ease-in;
  }
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
