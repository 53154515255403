<template>
  <div>
    <h3 class="text-xl font-bold text-neutral-800">Changelog</h3>
    <p class="text-neutral-800">
      Announce product changes with a dedicated changelog page. You can
      customize the page here.
    </p>

    <div class="toggle-wrapper mt-4">
      <div
        class="button--toggle"
        :class="{ 'area--disabled': isChangelogToggleLoading }"
      >
        <input
          type="checkbox"
          class="checkbox"
          @change="toggleChangelog()"
          v-model="isChangelogActive"
        />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span v-if="!isChangelogToggleLoading" key="loading1">
          <span
            v-if="isChangelogActive"
            key="isactive"
            class="flex items-center"
          >
            <span><strong>On</strong> – Changelog page is active </span>
          </span>
          <span v-else key="ispublic" class="flex items-center">
            <span><strong>Off</strong> – Changelog page is disabled </span>
          </span>
        </span>
        <span class="text--lighter" v-else key="loading2">Saving …</span>
      </transition>
    </div>

    <form
      enctype="multipart/form-data"
      action="/"
      accept-charset="UTF-8"
      method="post"
      class=""
    >
      <input type="hidden" name="_method" value="patch" />
      <input type="hidden" name="authenticity_token" :value="this.form_token" />
      <input type="hidden" name="settings" value="changelog" />
      <h4 class="mt-4 font-semibold text-neutral-800">Page name</h4>
      <div class="mt-2">
        <input
          type="text"
          class="input"
          name="[project][changelog_page_name]"
          :value="this.changelog_page_name"
          maxlength="30"
        />
      </div>
      <h4 class="mt-4 font-semibold text-neutral-800">Page heading</h4>
      <div class="mt-2">
        <input
          type="text"
          class="input"
          name="[project][changelog_intro_heading]"
          :value="this.changelog_intro_heading"
          maxlength="30"
        />
      </div>

      <h4 class="mt-4 font-semibold text-neutral-800">Page description</h4>
      <div class="mt-2">
        <textarea
          rows="3"
          class="input"
          name="[project][changelog_intro_text]"
          :value="this.changelog_intro_text"
          maxlength="300"
        />
      </div>
      <div class="mt-4">
        <ImageUploader
          imageLable="Header image"
          :image="changelog_header_image_preview || changelog_header_image"
          :removeImage="remove_changelog_header_image"
          @deleteImg="deleteHeaderImage"
          id="changelog_header_image"
          @changeImg="changeHeaderImage"
          buttonName="Upload header image"
          name="project[changelog_header_image]"
        />
        <input
          type="text"
          name="project[remove_changelog_header_image]"
          id="deleteChangelogHeaderImage"
          class="hidden"
        />
      </div>
      <div class="relative mt-4 mb-6">
        <ColorPicker
          :current-color="changelog_heading_font_color"
          input-name="project[changelog_heading_font_color]"
          label="Header text color"
          v-model="changelog_heading_font_color"
        />
      </div>
      <button class="btn-main" type="submit">Save</button>
    </form>
  </div>
</template>

<script>
import ImageUploader from "../../components/image_uploader.vue";
import ColorPicker from "../../color-picker.vue";

export default {
  components: {
    ImageUploader,
    ColorPicker
  },
  props: {
    changelogActive: Boolean,
    token: String,
    changelogIntroHeading: String,
    changelogIntroText: String,
    changelogPageName: String,
    changelogHeaderImage: String,
    changelogHeadingFontColor: String,
  },
  data: function () {
    return {
      isChangelogActive: this.changelogActive,
      isChangelogToggleLoading: false,
      needsRefresh: false,
      form_token: this.token,
      changelog_intro_heading: this.changelogIntroHeading,
      changelog_intro_text: this.changelogIntroText,
      changelog_page_name: this.changelogPageName,
      changelog_header_image: this.changelogHeaderImage || null,
      changelog_header_image_preview: null,
      remove_changelog_header_image: 0,
      changelog_heading_font_color: this.changelogHeadingFontColor || '#2E3F51',
    };
  },

  methods: {
    toggleChangelog: function () {
      this.isChangelogToggleLoading = true;

      Rails.ajax({
        url: "/toggle_changelog",
        type: "POST",
        data: "",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isChangelogActive = data.changelog_active;
          this.isChangelogToggleLoading = false;
          this.needsRefresh = true;
          window.location.reload();
        },
        error: (data, status) => {
          console.log(data);
          this.isChangelogToggleLoading = false;
        },
      });
    },
    changeHeaderImage(e) {
      if (!e.target.files || !e.target.files[0]) {
        return;
      }
      
      if (e.target.files[0].name.includes(".svg")) {
        e.target.value = '';
        this.changelog_header_image = '';
        this.changelog_header_image_preview = null;
      } else {
        const file = e.target.files[0];
        try {
          this.changelog_header_image_preview = URL.createObjectURL(file);
          this.changelog_header_image = file;
          document.getElementById('deleteChangelogHeaderImage').value = 'off';
        } catch (error) {
          console.error('Error creating preview URL:', error);
          this.changelog_header_image = '';
          this.changelog_header_image_preview = null;
        }
      }
    },
    deleteHeaderImage() {
      document.getElementById('changelog_header_image').value = '';
      document.getElementById('deleteChangelogHeaderImage').value = 'on';
      this.changelog_header_image = '';
      this.changelog_header_image_preview = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.area--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
}

.button--toggle {
  position: relative;
  width: 74px;
  height: 36px;
  overflow: hidden;
  margin-right: 0.5rem;
}

.button--toggle,
.button--toggle .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #f5f5f5;
  transition: 0.3s ease all;
  z-index: 1;
  border: 1px solid gainsboro;
}

.button--toggle .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background-color: #d2d2d2;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.button--toggle .checkbox:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

.button--toggle .checkbox:checked:active + .knobs:before {
  margin-left: -26px;
}

.button--toggle .checkbox:checked + .knobs:before {
  content: "";
  left: 42px;
  background-color: #3bd087;
  @media only screen and (min-width: 381px) and (max-width: 420px) {
    left: 40px;
  }
  @media only screen and (min-width: 320px) and (max-width: 350px) {
    left: 25px;
  }
  @media only screen and (min-width: 351px) and (max-width: 380px) {
    left: 32px;
  }
}

.button--toggle .checkbox:checked ~ .layer {
  background-color: #d1f0e1;
  border-color: #a3e6c6;
}

.roadmap-screenshot-wrapper {
  height: 95px;
  margin-top: 0.5rem;
}

.roadmap-screenshot {
  max-width: 100%;
  display: block;
  border-radius: 4px;
}

/* Animations */

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

.fadeIn-enter-to,
.fadeIn-leave {
  opacity: 1;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 200ms ease-in;
}

.fromBottom-move {
  transition: all 250ms;
}
.fromBottom-enter-active,
.fromBottom-leave-active {
  transition: all 250ms;
  position: absolute;
}
.fromBottom-enter,
.fromBottom-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.fromLeft-move {
  transition: all 250ms;
}
.fromLeft-enter-active,
.fromLeft-leave-active {
  transition: all 250ms;
}
.fromLeft-enter,
.fromLeft-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
</style>
