<template>
	<div class="flex flex-col pt-16 mt-20 justify-center items-center">
		<div class="pl-16 pr-16 py-16 rounded-xl shadow-lg child-div flex justify-center items-center flex-col">
			<img src="../../assets/images/not_found.svg"/>
			<h1 class="mt-4 text-3xl font-bold leading-9 tracking-normal text-center text-gray-700">Idea not found</h1>
			<p class="mt-2 text-sm font-normal leading-5 tracking-normal text-center text-gray-500">The idea you are looking for doesn't exist</p>
			<a 
			  :href="url"
			  class="
			    btn-main
				mt-4"
			  >Go to all ideas</a>
		</div>
	</div>
</template>

<script>
	export default {
		props:["url"],
	}
</script>

<style scoped>

</style>