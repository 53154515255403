<template>
    <div class="flex">
        <div class="w-full">
            <div class="mt-4">
                <label class="label" >Project Name</label>
                <input type="text" name="project[name]" v-model="project.name" class="input" >
            </div>
            <div class="mt-4">
                <label class="label" >Project Subdomain</label>
                <input type="text" name="project[subdomain]" v-model="project.subdomain" class="input" >
            </div>

            <div class="mt-4">
              <ColorPicker
                :current-color="project.color"
              input-name="project[color]"
              label="Accent color"
                v-model="project.color"
                show-text="true"
              />
            </div>

            <input type="hidden" name="settings" value="general" />
            <div class="mt-4">
              <ImageUploader
                imageLable="Logo"
                :image="logo"
                :removeImage="remove_logo"
                @deleteImg="deleteImage"
                id="logo"
                @changeImg="changeImage"
                buttonName="Upload logo"
              />
            </div>

            <div class="mt-4">
              <label class="label">Logo Click Destination URL</label>
              <input type="text" v-model="project.logo_url" name="project[logo_url]" class="input" placeholder="Enter logo URL">
            </div>

            <div class="mt-4">
              <ImageUploader
                imageLable="Favicon"
                :image="favicon_image"
                :removeImage="remove_logo"
                @deleteImg="deleteImage"
                id="favicon_image"
                @changeImg="changeImage"
                buttonName="Upload icon"
              />
            </div>
            <div class="mt-1 text-sm text-gray-500">
              <label>The recommended image size is 256x256.</label>
            </div>

            <div class="mt-4">
                <label class="label" >Choose what is displayed as your project's home screen.</label>
                <div class="relative max-w-md">
                  <select
                    id="cars"
                    name="project[home_screen]"
                    v-model="project.home_screen"
                    class="input select"
                  >
                    <option v-for="option in project.home_screen_options" :key="option[0]" :value="option[1]">{{option[0]}}</option>
                  </select>
                  <svg
                    class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
            </div>

            <div class="mt-6">
                <input
                  type="text"
                  name="project[remove_logo]"
                  id="deleteLogo"
                  class="hidden"
                >
                <input
                  type="text"
                  name="project[remove_favicon]"
                  id="deleteFavicon"
                  class="hidden"
                >
                <input type="submit" class="btn-main" value="Save" />
            </div>
        </div>
    </div>
</template>

<script>
import ColorPicker from "../color-picker.vue";
import ImageUploader from "../components/image_uploader";
export default {
  props: ['name', 'subdomain', 'url', 'squared_url', 'color', 'home_screen', 'home_screen_options', 'logo_url'],

  created(){
    this.project = {
      name: this.name,
      subdomain: this.subdomain,
      logo: this.url,
      logo_url: this.logo_url,
      color: this.color,
      favicon_image: this.squared_url,
      home_screen: this.home_screen,
      home_screen_options: JSON.parse(this.home_screen_options)
    }
  },
  data() {
    return {
      project: {},
      remove_logo:0,
      logo: this.url,
      favicon_image: this.squared_url,
      board: {
        id: 0,
        name: "Feature requests"
      },
      posts: {
        first: {
          id: 0,
          title: "",
          status: "done",
          human_status: "Done"
        },
        second: {
          id: 0,
          title: "",
          status: "in_progress",
          human_status: "In progress"
        },
        third: {
          id: 0,
          title: "",
          status: "planned",
          human_status: "Planned"
        }
      },
      activeStep: 0
    };
  },
  components: {
    ImageUploader,
    ColorPicker
  },

  methods: {
    changeImage(e,id) {
      if (id=="favicon_image"){
        if (e.target.files[0].name.includes(".svg")) {
          e.target.value=''
          this.favicon_image = ''
        }
        else{
          this.favicon_image = e.target.files[0]
        }
      }
      if(id=="logo"){
        if (e.target.files[0].name.includes(".svg")) {
          e.target.value=''
          this.logo = ''
        }
        else{
          this.logo = e.target.files[0]
        }
      }
    },
    deleteImage(id) {
      document.getElementById(id).value = ''

      if (id=="favicon_image"){
        document.getElementById('deleteFavicon').value = 'on'
        this.favicon_image = ''
      }
      if(id=="logo"){
        document.getElementById('deleteLogo').value = 'on'
        this.logo = ''
      }
    }
  },

  computed: {
    firstPost() {
      if (this.posts.first.title) {
        return this.posts.first;
      }

      if (this.posts.second.title) {
        return this.posts.second;
      }

      if (this.posts.third.title) {
        return this.posts.third;
      }
    },

    secondPost() {
      if (this.posts.first.title && this.posts.second.title) {
        return this.posts.second;
      }

      if (
        this.posts.first.title &&
        !this.posts.second.title &&
        this.posts.third.title
      ) {
        return this.posts.third;
      }

      if (
        !this.posts.first.title &&
        this.posts.second.title &&
        this.posts.third.title
      ) {
        return this.posts.third;
      }
    },

    thirdPost() {
      if (
        this.posts.first.title &&
        this.posts.second.title &&
        this.posts.third.title
      ) {
        return this.posts.third;
      }
    },
  }
};
</script>

<style scoped>
.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

.fadeIn-enter-to,
.fadeIn-leave {
  opacity: 1;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  @apply transition duration-200 ease-in;
}

.browser-window {
  height: 15rem;
  @apply rounded-lg w-full max-w-lg mx-auto shadow-lg flex flex-col;
}

.browser-container {
  margin-top : 11.5rem;
}

.browser-window__tabs {
  @apply bg-dblue-200 h-10 w-full flex items-end rounded-t-md;
}

.browser-window__tabs .content {
  line-height: 2rem;
  @apply flex items-center h-8 w-48 bg-dblue-100 ml-2 rounded-t px-2 text-sm text-dblue-500 ring-1 ring-black ring-opacity-5 relative z-10;
}

.browser-window__tabs .content span {
  overflow: hidden;
  display: block;
  max-height: 100%;
  word-break: break-all;
}

.browser-window__tabs .content::before {
  content: "";
  right: 0;
  top: 0;
  width: 2rem;
  background: linear-gradient(90deg, rgba(234, 236, 238, 0), #eaecee);
  @apply absolute h-full rounded-tr;
}

.browser-window__tabs .content::after {
  content: "";
  height: 1px;
  bottom: -1px;
  left: 0;
  @apply block absolute w-full bg-dblue-100;
}

.browser-window__url {
  @apply w-full h-10 bg-dblue-100 flex items-center relative z-0 ring-1 ring-black ring-opacity-5;
}

.browser-window__url .content {
  line-height: 1.5rem;
  @apply block h-6 w-full mx-2 rounded-full bg-white px-2 text-sm;
}

.browser-window__body {
  @apply pt-2 px-3;
}

.browser-window__project-name {
  @apply text-base;
}

.navbox {
  @apply bg-dblue-200 rounded h-3;
}

.textbox {
  @apply bg-dblue-100 rounded;
}

.col {
  @apply bg-white shadow-sm rounded p-2 relative;
}

.post {
  height: 2.4rem;
  @apply border-2 border-dblue-100 rounded m-1 bg-white text-sm px-2 flex flex-col justify-center;
}

.post__title {
  @apply text-sm text-dblue-500 leading-tight;
}

.post__status {
  @apply text-xs font-medium leading-none;
}

.text-planned {
  color: #5a73c1;
}

.text-in_progress {
  color: #05867b;
}

.text-done {
  color: #0b8200;
}

</style>
