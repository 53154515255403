<template>
	<div class="flex flex-col pt-32 justify-center items-center">
		<img class="mb-16 " src="../../assets/images/logo.svg"/>
		<div class="pl-16 pr-16 py-16 rounded-xl shadow-lg child-div flex justify-center items-center flex-col">
			<img src="../../assets/images/not_found.svg"/>
			<h1 class="mt-4 text-3xl font-bold leading-9 tracking-normal text-center text-gray-700">Page not found</h1>
			<p class="mt-2 text-sm font-normal leading-5 tracking-normal text-center text-gray-500">The page you are looking for doesn't exist</p>
			<a 
			  :href="linkUrl"
			  class="
				btn-main
				mt-4"
			  >Go to FeedBear</a>
		</div>
	</div>
</template>

<script>
	export default {
		props:['link-url'],
	}
</script>

<style scoped>
</style>