<template>
  <div class="">
    <div class="flex flex-wrap items-center justify-start">
      <div
        class="w-20 h-20 mr-2 shadow-lg mb-2 border border-neutral-200 rounded-lg"
        v-for="file in uploadedFiles"
        :key="file.id + '_img'"
      >
        <img
          :src="file.preview"
          class="object-cover h-20 w-full rounded-lg"
          alt
        />
      </div>
    </div>
    <input
      type="hidden"
      :name="name"
      v-for="file in uploadedFiles"
      :key="file.id"
      :value="file.response.signed_id"
    />
    <a
      href="#"
      :id='upyId'
      class="inline-flex items-center text-sm text-gray-700 hover:no-underline hover:text-project-500"
      @click.prevent="resetUppy()"
    >
      <svg
        class="w-4 h-4 mr-1"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
        ></path>
      </svg>
      <span
        v-if="this.attachFile"
        >{{ (this.attachFile[0] || this.uploadedFiles[0])? $t("Replace") : $t("Attach") }}&nbsp;{{
          noun
        }}</span
      >
      <span
        v-else
        >{{ uploadedFiles.length > 0 ? $t("Replace") : $t("Attach") }}&nbsp;{{
          noun
        }}</span
      >
    </a>
  </div>
</template>

<script>
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import EventBus from "event_bus";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default {
  props: ["name", "noun","attach-file",'upy-id'],
  data() {
    return {
      uploadedFiles: [],
      uppyInstance: null,
    };
  },
  mounted() {
    EventBus.$on("imagesUpload", (data) => {
      this.uploadedFiles=[];
    });
    let direct_upload_url = document
      .querySelector("meta[name='direct-upload-url']")
      .getAttribute("content");
    this.uppyInstance = Uppy({
      autoProceed: false,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: 10485760, // 10MB
        allowedFileTypes: ["image/*"]
      }
    });
    this.uppyInstance.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    });
    this.uppyInstance.use(Dashboard, {
      trigger: '#'+this.upyId,
      closeAfterFinish: true,
      showRemoveButtonAfterComplete: true,
      note: "Only images up to 10MB",
      closeModalOnClickOutside: true,
      proudlyDisplayPoweredByUppy: false,
    });

    this.uppyInstance.on("file-remove", (file, reason) => {
      if (reason === "removed-by-user") {
        // delete file from AS
      }
    });

    this.uppyInstance.on("complete", result => {
      this.uploadedFiles = result.successful.filter(img => img.extension != 'svg');
      EventBus.$emit("uploadedImage", true);

    });
  },

  methods: {
    resetUppy() {
      this.uppyInstance.reset();
    },
  }
};
</script>

<style></style>
