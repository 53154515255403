<template>
  <span class="mr-1 text-sm" v-if="is_shown">
    <a
      class="text-neutral-800 hover:no-underline hover:text-project-500"
      :href="this.url"
      >{{ $t("Edit post") }}</a
    >
    <a
      class="text-red-600 hover:no-underline hover:text-red-700 ml-2"
      @click.prevent="confirmDelete"
      href="#"
    >{{ $t("Delete post") }}</a>
    <span class="text-neutral-700 tabular-nums">
      (<countdown
        :original-date="this.datefrom"
        @countdone="is_shown = false"
      ></countdown
      >)</span
    >
  </span>
</template>

<script>
import Countdown from "../countdown.vue";

export default {
  props: ["url", "datefrom", "delete_url"],

  data: function() {
    return {
      is_shown: true
    };
  },

  methods: {
    confirmDelete() {
      if (confirm(this.$t("Do you really want to delete this idea?"))) {
        fetch(this.delete_url, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }).then(response => {
          if (response.ok) {
            window.location.href = this.delete_url;
          } else {
            throw new Error('Delete failed');
          }
        }).catch(error => {
          console.error('Error:', error);
          alert(this.$t("An error occurred while deleting the post"));
        });
      }
    }
  },

  components: {
    countdown: Countdown
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
