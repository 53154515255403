<template>
  <div>
    <div
      v-if="type == 'edit'"
      @click="login_dialog_visible = true"
      class="text-dblue-400 hover:text-project-500 pl-2 transition duration-150 ease-in-out"
      :style="{ cursor: 'pointer' }"
    >
      <simple-svg :filepath="editIcon" :width="'18px'" :height="'18px'" />
    </div>
    <div
      v-if="type == 'new'"
      @click="login_dialog_visible = true"
      class="dropdown-item rounded-lg cursor_point"
    >
      <svg
        class="w-6 h-6 mr-1"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        ></path>
      </svg>
      <span>New board</span>
    </div>
    <div
      v-if="type == 'project-setting'"
      @click="login_dialog_visible = true"
      class="dropdown-item cursor_point"
    >
      <svg
        class="w-5 h-5 mr-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          fill-rule="evenodd"
          d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span>Project Settings</span>
    </div>
    <div
      v-if="type == 'new-project'"
      @click="login_dialog_visible = true"
      class="flex justify-between dropdown-item cursor_point"
    >
      <span>New project</span>
      <svg
        class="w-6 h-6 mr-1"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        ></path>
      </svg>
    </div>
    <div
      v-if="type == 'my-account'"
      @click="login_dialog_visible = true"
      class="dropdown-item cursor_point"
    >
      <svg
        class="w-5 h-5 mr-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
        ></path>
      </svg>
      <span>My Account</span>
    </div>

    <Dialog 
      :title="screen === 'main' ? $t('Sign up') + ' / ' + $t('Log in') : screen === 'magic_link' ? $t('Log in') : $t('Sign in with password')"
      v-model="login_dialog_visible"
      :back-function="screen !== 'main' ? backToMain : null"
    >
      <div class="px-4 py-4 pb-6">
        <!-- <div class="flex items-center justify-center mt-2 space-x-2">
          <a class="btn-soc" @click.prevent="showLoginWithSendPassword">
            <svg
              class="w-6 h-6 text-neutral-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              ></path>
            </svg>
          </a>

          <a :href="this.googleHref" class="btn-soc">
            <svg
              role="img"
              class="w-6 h-6"
              style="color: #4285f4"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"
              />
            </svg>
          </a>
        </div> -->
        <div v-if="this.screen == 'main'">
          <div>
            <a
              :href="this.googleHref"
              class="w-full bg-white border border-neutral-400 rounded-lg hover:bg-neutral-100 hover:no-underline px-4 py-3 flex items-center justify-center gap-2"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="18px"
                  height="18px"
                  viewBox="0 0 18 18"
                  version="1.1"
                >
                  <title>logo_googleg_48dp</title>
                  <desc>Created with Sketch.</desc>
                  <g
                    id="Google-Button"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="btn_google_signin_dark_normal_web"
                      transform="translate(-15.000000, -15.000000)"
                    >
                      <g
                        id="logo_googleg_48dp"
                        transform="translate(15.000000, 15.000000)"
                      >
                        <path
                          d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
                          id="Shape"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
                          id="Shape"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
                          id="Shape"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
                          id="Shape"
                          fill="#EA4335"
                        ></path>
                        <polygon
                          id="Shape"
                          points="0 0 18 0 18 18 0 18"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <span class="text-black">Continue with Google</span>
            </a>
          </div>

          <div class="or my-6 text-sm">
            <span class="text-center text-neutral-800"
              >Or use your email and password</span
            >
          </div>

          <div class="flex flex-col gap-2">
            <button
              class="w-full bg-white border border-neutral-400 rounded-lg hover:bg-neutral-100 hover:no-underline px-4 py-3 flex items-center justify-center gap-2"
              @click.prevent="
                screen = 'magic_link';
                isSendLink = true;
              "
            >
              <svg
                data-v-7f08cb0e=""
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 text-neutral-500"
              >
                <path
                  data-v-7f08cb0e=""
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
              Sign in with magic link
            </button>
            <button
              class="w-full bg-white border border-neutral-400 rounded-lg hover:bg-neutral-100 hover:no-underline px-4 py-3 flex items-center justify-center gap-2"
              @click.prevent="
                screen = 'password';
                isSendLink = true;
              "
            >
              <svg
                data-v-7f08cb0e=""
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 text-neutral-500"
              >
                <path
                  data-v-7f08cb0e=""
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
              Sign in with password
            </button>
          </div>
        </div>
        <div v-if="screen == 'magic_link'">
          <div v-if="this.isSendLink">
            <form
              id="new_user"
              action="/users/sign_in"
              @submit.prevent="sendLink"
              accept-charset="UTF-8"
              method="post"
              class="form p-3"
              ref="loginform"
            >
              <input
                type="hidden"
                name="authenticity_token"
                :value="this.authToken"
              />
              <input
                type="hidden"
                name="user_intent"
                :value="this.userIntent"
              />
              <div class="p-2 text-center">
                {{ $t("A verification link will sent to your email") }}.
              </div>
              <div>
                <label for="user_email" class="label">{{ $t("Email") }}</label>
                <input
                  autocomplete="email"
                  required="required"
                  type="email"
                  value=""
                  name="user[email]"
                  id="user_email"
                  class="input"
                />
              </div>
              <div class="mt-4 flex justify-center">
                <input
                  type="submit"
                  value="Send magic link"
                  class="text-center w-full btn-main min-h-[48px]"
                />
              </div>
            </form>
          </div>
          <div
            class="container"
            v-if="!this.isSendLink && !this.email_does_not_exists"
          >
            <p class="alert alert--good">
              {{ $t("A verification link has been sent to your email") }}
            </p>
          </div>
          <div
            class="container"
            v-if="!this.isSendLink && this.email_does_not_exists"
          >
            <p class="alert alert--good">
              {{ $t("If you have an account with us, we've sent an email to") }}
              {{ this.email_user }} {{ $t("with a link to login") }}
            </p>
          </div>
        </div>
        <div v-if="screen == 'password'">
          <div v-if="this.isSendLink">
            <form
              id="new_user"
              action="/users/sign_in"
              @submit.prevent="signInWithPassword"
              accept-charset="UTF-8"
              method="post"
              class="form"
              ref="loginform"
            >
              <div
                class="bg-red-100 rounded border border-red-300 text-red-900 px-4 py-3 mb-4"
                v-if="login_error_msg"
              >
                <p>{{ login_error_msg }}</p>
                <p class="mt-1">
                  {{$t("Forgot your password?")}}
                  <a
                    href="#"
                    @click.prevent="passwordResetToggle()"
                    class="text-red-900 underline hover:text-red-800 hover:no-underline"
                    >{{ $t("Click here") }}</a
                  >
                </p>
              </div>
              <input
                type="hidden"
                name="authenticity_token"
                :value="this.authToken"
              />
              <div class="mt-4">
                <label for="user_email" class="label">{{ $t("Email") }}</label>
                <input
                  autocomplete="email"
                  required="required"
                  type="email"
                  :value="current_user_email"
                  name="user[email]"
                  id="user_email"
                  class="input"
                />
              </div>
              <div class="mt-4">
                <label for="user_password" class="label">{{
                  $t("Password")
                }}</label>
                <input
                  autocomplete="off"
                  required="required"
                  type="password"
                  name="user[password]"
                  id="user_password"
                  class="input"
                />
              </div>
              <div class="mt-4 flex justify-center">
                <input
                  type="submit"
                  name="commit"
                  value="Log in"
                  class="text-center w-full btn-main min-h-[48px]"
                />
                <!-- <input
                  type="submit"
                  name="commit"
                  value="Log in with email"
                  class="btn-spacing text-neutral-800"
                  @click.prevent="toggleLoginBtn"
                /> -->
              </div>
              <!-- <div class="p-4 text-center">
                <a href="#" @click.prevent="passwordResetToggle()">{{
                  $t("Set a new password")
                }}</a>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog title="Set a new password" v-model="password_forgot_dialog_visible"
    :back-function="screen !== 'main' ? backToMain : null"
    >
      <transition name="popdown">
        <div
          class="p-4"
          v-if="this.message && !this.forgot_email_does_not_exist"
        >
          <p
            class="px-6 py-4 bg-green-100 text-green-800 border border-green-300 rounded-lg"
          >
            {{
              $t(
                "You will receive an email with instructions on how to reset your password in a few minutes"
              )
            }}.
          </p>
        </div>
        <div
          class="p-4"
          v-if="this.message && this.forgot_email_does_not_exist"
        >
          <p
            class="px-6 py-4 bg-green-100 text-green-800 border border-green-300 rounded-lg"
          >
            {{ $t("If you have an account with us, we've sent an email to") }}
            {{ this.email_user }}
            {{ $t("with a link that you can use to reset your password") }}
          </p>
        </div>
        <div class="px-4 pb-8 pt-4" v-if="!this.message">
          <form
            id="forgot_password"
            action="/users/password"
            @submit.prevent="forgotPassword"
            accept-charset="UTF-8"
            method="post"
            class="form"
            v-if="this.isSendLink"
            ref="forgotloginform"
          >
            <input
              type="hidden"
              name="authenticity_token"
              :value="this.authToken"
            />
            <input type="hidden" name="user_intent" :value="currentPath" />
            <div class="mt-2">
              <label for="user_email" class="label">{{ $t("Email") }}</label>
              <input
                autocomplete="email"
                required="required"
                type="email"
                value=""
                name="user[email]"
                id="forgot_password"
                class="input"
              />
            </div>
            <div class="mt-4 flex justify-center">
              <input
                type="submit"
                name="commit"
                value="Send email"
                class="text-center w-full btn-main min-h-[48px]"
              />
              <!-- <input
                type="submit"
                name="commit"
                value="Click here to login"
                class="btn-spacing text-neutral-800"
                @click.prevent="toggleLoginBtn1"
              /> -->
            </div>
          </form>
        </div>
      </transition>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "./dialog";
import editIcon from "../../assets/images/icons/edit.svg";

export default {
  props: [
    "type",
    "auth-token",
    "popup",
    "user_intent",
    "google-href",
    "twitter-href",
    "fb-href",
    "current_user_email",
  ],
  data() {
    return {
      login_dialog_visible: false,
      signup_dialog_visible: false,
      isSendLink: true,
      login_error_msg: "",
      reg_error_msgs: [],
      editIcon,
      userIntent: "",
      login_togle: true,
      password_forgot_dialog_visible: false,
      message: false,
      email_does_not_exists: false,
      forgot_email_does_not_exist: false,
      email_user: "",
      screen: 'main',
    };
  },
  computed: {
    currentPath() {
      return window.location.pathname;
    },
  },
  components: {
    Dialog,
    editIcon,
  },
  mounted() {
    this.login_dialog_visible = this.popup == "true" ? true : false;
    this.userIntent = this.user_intent ? this.user_intent : "";
    this.isSendLink = true;
    this.login_togle = true;
  },
  methods: {
    backToMain() {
      this.screen = 'main';
      this.isSendLink = true;
      this.login_togle = true;
      this.login_error_msg = '';
      this.message = false;
      this.email_does_not_exists = false;
      this.forgot_email_does_not_exist = false;
      this.password_forgot_dialog_visible = false;
      this.login_dialog_visible = true;
    },
    toggleLoginBtn() {
      this.login_togle = !this.login_togle;
      this.screen = this.login_togle ? 'magic_link' : 'password';
    },
    passwordResetToggle() {
      this.login_dialog_visible = false;
      this.password_forgot_dialog_visible = true;
    },
    toggleLoginBtn1() {
      this.password_forgot_dialog_visible = false;
      this.login_dialog_visible = true;
    },
    signInWithPassword() {
      var data = new FormData(this.$refs.loginform);
      Rails.ajax({
        url: "/users/sign_in",
        type: "POST",
        data: data,
        dataType: "json",
        success: (data) => {
          let params = new URL(document.location).searchParams;
          let update_payment = params.get("update_payment");
          if (update_payment == "true") {
            window.location.href = "/edit?view=billing";
          } else {
            window.location.href = document.location.split("?")[0];
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_dialog_visible = true;
          this.login_error_msg = data.error_message;
        },
      });
    },

    forgotPassword() {
      var data = new FormData(this.$refs.forgotloginform);
      this.email_user = data.get("user[email]");
      Rails.ajax({
        url: "/users/forgot/link",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if (data == "notExist") {
            this.forgot_email_does_not_exist = true;
            this.password_forgot_dialog_visible = true;
            this.message = true;
          } else {
            this.forgot_email_does_not_exist = false;
            this.password_forgot_dialog_visible = true;
            this.message = true;
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },

    sendLink() {
      // this.login_dialog_visible = false
      var data = new FormData(this.$refs.loginform);
      this.email_user = data.get("user[email]");

      let params = new URL(document.location).searchParams;
      if ("true" == params.get("update_payment")) {
        data.set("update_payment", true);
      }

      Rails.ajax({
        url: "/users/send/link",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          // console.log('helow')
          if (data == "notExist") {
            this.email_does_not_exists = true;
            this.isSendLink = false;
          } else {
            this.email_does_not_exists = false;
            this.isSendLink = false;
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },
    signIn() {
      var data = new FormData(this.$refs.loginform);

      Rails.ajax({
        url: "/users/sign_in",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          reloadWithTurbolinks();
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },
    showLoginWithSendPassword() {
      this.isSendLink = !this.isSendLink;
    },
  },
};
</script>

<style scoped>
.btn-soc {
  @apply p-2 shadow-md rounded-lg border border-neutral-100 transition duration-150 ease-in-out;
}

.btn-soc:hover {
  @apply shadow-lg;
}
.btn-spacing {
  margin-left: 25px;
  background-color: white;
  cursor: pointer;
}
.cursor_point {
  cursor: pointer;
}

.or {
  @apply flex justify-center relative;

  span {
    @apply bg-white px-2 z-10 text-neutral-700;
  }

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: theme("colors.neutral.400");
    transform: translateY(-50%);
    top: 50%;

    @apply z-0;
  }
}


.alert {
  animation: none!important;
  position: relative!important;
  top: unset!important;
  left: unset!important;
  right: unset!important;
}
</style>
