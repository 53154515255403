<template>
  <div>
    <div class="flex flex-col md:flex-row justify-center items-center mt-4 flex-wrap">
      <div
        class="max-w-xs w-full flex-1 border rounded-lg shadow-md p-5 m-4 relative hover:shadow-lg bg-brand-50 border-dblue-500 transition-shadow duration-150 ease-in-out"
      >
        <div class="plans__item__badge font-semibold">Best value</div>
        <h4 class="text-center font-medium text-xl">Business</h4>
        <a
          href="#"
          class="w-full justify-center mt-3 bg-dblue-500 text-white rounded-lg px-4 py-2 font-semibold text-center hover:bg-white hover:text-dblue-500 transition duration-150 ease-in-out border border-transparent hover:border-dblue-500 block hover:no-underline"
          @click.prevent="handlePlanSelection('business')"
          >Choose this plan</a
        >
        <p class="font-semibold mt-4 flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span>$99/month</span>
        </p>
        <p class="mt-1 flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span>Up to 10 team members</span>
        </p>
        <p class="mt-1 flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span>Single Sign-On</span>
        </p>
        <p class="mt-1 flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span>Private projects</span>
        </p>
      </div>
      <div
        class="max-w-xs w-full flex-1 border border-neutral-200 rounded-lg shadow-md p-5 m-4 relative hover:shadow-lg transition-shadow duration-150 ease-in-out"
      >
        <h4 class="text-center font-medium text-xl">Startup</h4>
        <a
          href="#"
          class="w-full justify-center mt-3 bg-dblue-500 text-white rounded-lg px-4 py-2 font-semibold text-center hover:bg-white hover:text-dblue-500 transition duration-150 ease-in-out border border-transparent hover:border-dblue-500 block hover:no-underline"
          @click.prevent="handlePlanSelection('startup')"
          >Choose this plan</a
        >
        <p class="font-semibold mt-4 flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span>$29/month</span>
        </p>
        <p class="mt-1 flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path></svg
          ><span>Up to 3 team members</span>
        </p>
      </div>
    </div>
    <p class="text-neutral-700 text-center mt-4">Included with all plans:</p>
    <p class="text-neutral-700 text-center">
      Unlimited boards &middot; Unlimited users &middot; Custom domain
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      planUpgradeOrDowngradeMessages: null
    };
  },
  methods: {
    ...mapGetters(['getPlanCompareMessages']),
    handlePlanSelection: function (plan_name) {
      this.$emit("plan-selection", plan_name);
    },
    async validatePlan(plan_name){
      await this.$store.dispatch('comparePlan', plan_name);
      this.planUpgradeOrDowngradeMessages = this.getPlanCompareMessages;
    }
  },
};
</script>
