<template>
  <dropdown>
    <div
      slot="button"
      tabindex="1"
      class="border bg-white border-neutral-300 rounded-lg bg-gradient-to-b from-white to-neutral-100 shadow-sm px-3 py-1 text-dblue-500 flex items-center focus:to-white focus:border-neutral-400"
      id="select-multipule-dropdown">
      <span>Tags</span>
      <span
        v-if="selectedTagsNames.length > 0 || currentTags.includes('untagged')"
        >:&nbsp;</span
      >
      <span v-if="selectedTagsNames.length === 1" class="text-neutral-800">{{
        selectedTagsNames[0]
      }}</span>
      <span v-if="selectedTagsNames.length > 1" class="text-neutral-800"
        >{{ selectedTagsNames.length }} tags</span
      >
      <span v-if="currentTags.includes('untagged')" class="text-neutral-800"
        >Untagged</span
      >
      <svg
        class="w-4 h-4 ml-1 -mr-1"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
    <div
      slot="items"
      class="flex flex-col py-2 w-56 border border-dblue-100 rounded-lg hidden"
      id="select-multiple-list"
    >
      <div
        v-for="tag in allTags"
        :key="parameterize(tag)"
        class="px-4 py-1 text-neutral-800 hover:no-underline hover:text-neutral-900 hover:bg-neutral-100 flex items-center"
      >
        <input
          type="checkbox"
          :name="parameterize(tag)"
          :id="parameterize(tag)"
          :value="parameterize(tag)"
          :checked="currentTags.includes(parameterize(tag))"
          @change="toggleTag(parameterize(tag))"
          class="custom-checkbox cursor-pointer"
        />
        <label
          :for="parameterize(tag)"
          class="filter-label flex-1 cursor-pointer"
        >
          {{ tag }}
        </label>
      </div>

      <div class="border-b w-full border-neutral-200 my-2"></div>

      <div
        class="px-4 py-1 text-neutral-800 hover:no-underline hover:text-neutral-900 hover:bg-neutral-100 flex items-center"
        id="select-multiple-checkbox"
      >
        <input
          type="checkbox"
          name="untagged"
          id="untagged"
          value="untagged"
          :checked="currentTags.includes('untagged')"
          @change="toggleUntagged"
          class="custom-checkbox cursor-pointer"
        />
        <label for="untagged" class="filter-label flex-1 cursor-pointer">
          Untagged
        </label>
      </div>
    </div>
  </dropdown>
</template>

<script>
export default {
  props: ["all-tags", "selected-tags"],
  data() {
    return {
      currentTags: this.selectedTags,
    };
  },

  created() {
    let current_url = new URL(window.location.href);
    let tagsFromUrl = current_url.searchParams.get("tags");

    if (tagsFromUrl) {
      this.currentTags = tagsFromUrl.split(",");
    }
  },

  computed: {
    selectedTagsNames() {
      return this.allTags.filter((tagName) => {
        return this.currentTags.includes(this.parameterize(tagName));
      });
    },
  },

  watch: {
    currentTags(oldVal, newVal) {
      let current_url = new URL(window.location.href);
      current_url.searchParams.set("tags", this.currentTags.join(","));
      window.history.replaceState({}, "", current_url);
    },
  },

  methods: {
    toggleTag(tagToToggle) {
      if (this.currentTags.includes(tagToToggle)) {
        this.currentTags = this.currentTags.filter((tag) => {
          return tag !== tagToToggle;
        });
      } else {
        this.currentTags.push(tagToToggle);
      }

      this.deselectUntagged();

      this.$emit("tagsChange", this.currentTags);
    },

    toggleUntagged: function () {
      if (this.currentTags.includes("untagged")) {
        this.currentTags = this.currentTags.filter((tag) => {
          return tag !== "untagged";
        });
      } else {
        this.currentTags = this.currentTags.filter(function (tag) {
          return tag === "untagged";
        });

        this.currentTags.push("untagged");
      }

      this.$emit("tagsChange", this.currentTags);
    },

    deselectUntagged: function () {
      this.currentTags = this.currentTags.filter(function (tag) {
        return tag != "untagged";
      });
    },

    parameterize: function (input) {
      return input
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "");
    },
  },
};
</script>

<style></style>
