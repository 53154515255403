<template>
  <div
    v-if="!comment.is_spam || currentProjectAdmin || comment.user.id === currentUser.id"
    class="mt-4 md:pl-4 pt-2 pb-2"
    :class="[
      comment.pin ? 'bg-neutral-100 rounded-xl pt-4 pb-4' : '',
      parentCommentId !== 0 ? 'pl-4' : 'pl-2.5',
    ]"
  >
    <div class="flex items-center" :class="{ '': parentCommentId !== 0 }">
      <div v-if="currentProjectAdmin">
        <tippy
          interactive
          :animate-fill="false"
          placement="bottom-start"
          theme="light"
          animation="fade"
          trigger="mouseenter"
        >
          <template v-slot:trigger>
            <avatar
              :class="{ 'comment__avatar--admin': comment.by_project_admin }"
              class="avatar shadow-md mr-4 border-2 border-white cursor-pointer"
              :size="36"
              :src="comment.user.avatar"
              :username="comment.user.name"
            ></avatar>
          </template>
          <tooltip-tippy :user="comment.user"></tooltip-tippy>
        </tippy>
      </div>
      <avatar
        v-else
        :class="{ 'comment__avatar--admin': comment.by_project_admin }"
        class="avatar shadow-md mr-4 border-2 border-white"
        :size="36"
        :src="comment.user.avatar"
        :username="comment.user.name"
      ></avatar>
      <div class="flex flex-col" id="comment-user-name">
        <div v-if="currentProjectAdmin">
          <tippy
            interactive
            :animate-fill="false"
            placement="bottom-start"
            theme="light"
            animation="fade"
            trigger="mouseenter"
          >
            <template v-slot:trigger>
              <h5
                class="text-neutral-800 font-semibold text-sm leading-snug cursor-pointer"
              >
                {{ comment.user.name }}

                <span
                  v-if="comment.by_project_admin"
                  class="text-gray-500 font-normal"
                  >({{ projectName }})</span
                >
              </h5>
            </template>
            <tooltip-tippy :user="comment.user"></tooltip-tippy>
          </tippy>
        </div>
        <h5 class="text-neutral-800 font-semibold text-sm leading-snug" v-else>
          {{ comment.user.name }}
        </h5>
        <span class="text-sm text-neutral-600 leading-snug">
          {{
            formatDistance(new Date(comment.created_at), new Date(), {
              locale: datefnLocales[getLocale],
              addSuffix: true,
            })
          }}
        </span>
      </div>
    </div>
    <div v-if="comment.is_spam && currentProjectAdmin" class="mt-2 mb-1 offset">
      <span
        class="inline-flex items-center gap-1 text-xs text-red-700 rounded-full px-2 py-1 bg-red-100"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" style="width: 12px; height: 12px;">
          <path fill="currentColor" d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16.212,8l-0.2,9h-2.024l-0.2-9 H16.212z M15.003,22.189c-0.828,0-1.323-0.441-1.323-1.182c0-0.755,0.494-1.196,1.323-1.196c0.822,0,1.316,0.441,1.316,1.196 C16.319,21.748,15.825,22.189,15.003,22.189z"></path>
        </svg>
        {{ $t("Marked as spam") }}
      </span>
    </div>
    <div v-if="edit_input_show">
      <comment-input
        class="mt-3 editComment edit-comment-input-container"
        :post-id="postId"
        :board-slug="boardSlug"
        :current-user="currentUser"
        :discussion-users="discussionUsers"
        :edit-comment="true"
        :comment-data="comment"
        @editHide="editHide"
        @updateCommentData="updateCommentData"
        :current-project-admin="currentProjectAdmin"
      ></comment-input>
    </div>
    <p
      class="mt-3 whitespace-pre-wrap break-words offset"
      :class="{ '': parentCommentId !== 0 }"
      v-html="
        $options.filters.highlightMentions(
          comment.body
            .replace(/\r\n|\\r\n|\\n/, '\n')
            .replace(/\\r/, '\r')
            .replace('${', '$ {')
        )
      "
      v-linkified:options="linkifyOpts"
      v-else
    ></p>

    <div
      class="flex items-center flex-wrap mt-2 offset"
      :class="{ '': parentCommentId !== 0 }"
      id="child-comment-attachment"
    >
      <a
        v-if="!edit_input_show"
        :href="file.url"
        target="_blank"
        v-for="(file, index) in comment.attached_files"
        :key="index"
        class="w-20 h-20 mr-2 shadow-lg border border-neutral-200 rounded-lg transform hover:scale-105 hover:shadow-xl transition duration-150 ease-in-out"
      >
        <img
          :src="file.preview_url"
          class="object-cover h-20 w-full rounded-lg"
          alt
        />
      </a>
    </div>

    <div
      class="flex mt-3 offset flex-col md:flex-row gap-2 md:items-center justify-between"
      :class="{ '': parentCommentId !== 0 }"
      id="comment-reply"
    >
      <!-- Regular actions -->
      <div class="flex items-center space-x-4">
        <a
          @click.prevent="showInputBox()"
          href="#"
          class="flex items-center gap-1 text-neutral-700 text-sm hover:no-underline hover:text-project-500 transition duration-150"
        >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14" height="14" viewBox="0 0 24 24">
            <path fill="currentColor" d="M 7.9804688 1.9902344 A 1.0001 1.0001 0 0 0 7.2929688 2.2929688 L 3.2929688 6.2929688 A 1.0001 1.0001 0 0 0 3.2929688 7.7070312 L 7.2929688 11.707031 A 1.0001 1.0001 0 1 0 8.7070312 10.292969 L 6.4140625 8 L 15 8 C 17.220375 8 19 9.7796254 19 12 L 19 20 A 1.0001 1.0001 0 1 0 21 20 L 21 12 C 21 8.6983746 18.301625 6 15 6 L 6.4140625 6 L 8.7070312 3.7070312 A 1.0001 1.0001 0 0 0 7.9804688 1.9902344 z"></path>
          </svg>
          <span>{{ $t("Reply") }}</span>
        </a>
        <a
          v-if="currentUser && currentUser.id == comment.user.id"
          @click.prevent="editShow()"
          href="#"
          class="flex items-center gap-1 text-neutral-700 text-sm hover:no-underline hover:text-project-500 transition duration-150"
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="14" height="14"><path fill="currentColor" d="M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z"></path></svg>
          <span>{{ $t("Edit") }}</span>
        </a>
        <a
          v-if="currentUser && currentUser.id == comment.user.id && !comment.by_project_admin"
          @click.prevent="showDeleteDialog(comment)"
          href="#"
          class="flex items-center gap-1 text-neutral-700 text-sm hover:no-underline hover:text-project-500 transition duration-150"
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="14" width="14">
              <g id="surface1">
              <path fill="currentColor" style=" " d="M 6.496094 1 C 5.675781 1 5 1.675781 5 2.496094 L 5 3 L 2 3 L 2 4 L 3 4 L 3 12.5 C 3 13.324219 3.675781 14 4.5 14 L 10.5 14 C 11.324219 14 12 13.324219 12 12.5 L 12 4 L 13 4 L 13 3 L 10 3 L 10 2.496094 C 10 1.675781 9.324219 1 8.503906 1 Z M 6.496094 2 L 8.503906 2 C 8.785156 2 9 2.214844 9 2.496094 L 9 3 L 6 3 L 6 2.496094 C 6 2.214844 6.214844 2 6.496094 2 Z M 4 4 L 11 4 L 11 12.5 C 11 12.78125 10.78125 13 10.5 13 L 4.5 13 C 4.21875 13 4 12.78125 4 12.5 Z M 5 5 L 5 12 L 6 12 L 6 5 Z M 7 5 L 7 12 L 8 12 L 8 5 Z M 9 5 L 9 12 L 10 12 L 10 5 Z "></path>
              </g>
            </svg>
            <span>{{ $t("Delete") }}</span>
      </a>
      </div>

      <!-- Admin actions -->
      <div v-if="currentProjectAdmin" class="flex items-center bg-gray-50 rounded-lg w-fit p-2 mr-2">
        <span class="text-sm text-neutral-700 mr-2">{{ $t("Admin") }}:</span>
        <div class="flex items-center space-x-3">
          <a
            @click.prevent="showDeleteDialog(comment)"
            href="#"
            class="flex items-center gap-1 text-neutral-700 text-sm hover:no-underline hover:text-project-500 transition duration-150"
          >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="14" width="14">
              <g id="surface1">
              <path fill="currentColor" style=" " d="M 6.496094 1 C 5.675781 1 5 1.675781 5 2.496094 L 5 3 L 2 3 L 2 4 L 3 4 L 3 12.5 C 3 13.324219 3.675781 14 4.5 14 L 10.5 14 C 11.324219 14 12 13.324219 12 12.5 L 12 4 L 13 4 L 13 3 L 10 3 L 10 2.496094 C 10 1.675781 9.324219 1 8.503906 1 Z M 6.496094 2 L 8.503906 2 C 8.785156 2 9 2.214844 9 2.496094 L 9 3 L 6 3 L 6 2.496094 C 6 2.214844 6.214844 2 6.496094 2 Z M 4 4 L 11 4 L 11 12.5 C 11 12.78125 10.78125 13 10.5 13 L 4.5 13 C 4.21875 13 4 12.78125 4 12.5 Z M 5 5 L 5 12 L 6 12 L 6 5 Z M 7 5 L 7 12 L 8 12 L 8 5 Z M 9 5 L 9 12 L 10 12 L 10 5 Z "></path>
              </g>
            </svg>
            <span>{{ $t("Delete") }}</span>
          </a>
          <a
            v-if="showPinButton && !comment.pin"
            @click.prevent="pinComment(comment)"
            href="#"
            class="flex items-center gap-1 text-neutral-700 text-sm hover:no-underline hover:text-project-500 transition duration-150"
          >
          <svg data-v-c287bfdc="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="currentColor" data-v-c287bfdc="" fill-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H14C14.5523 5 15 4.55228 15 4C15 3.44772 14.5523 3 14 3H3Z M3 7C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9H8C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7H3Z M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H7C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11H3Z M13 16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16L15 10.4142L16.2929 11.7071C16.6834 12.0976 17.3166 12.0976 17.7071 11.7071C18.0976 11.3166 18.0976 10.6834 17.7071 10.2929L14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7C13.7348 7 13.4804 7.10536 13.2929 7.29289L10.2929 10.2929C9.90237 10.6834 9.90237 11.3166 10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071L13 10.4142L13 16Z" clip-rule="evenodd"></path></svg>
            <span>{{ $t("Pin to top") }}</span>
          </a>
          <a
            v-if="comment.pin"
            @click.prevent="pinComment(comment)"
            href="#"
            class="flex items-center gap-1 text-neutral-700 text-sm hover:no-underline hover:text-project-500 transition duration-150"
          >
          <svg data-v-c287bfdc="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="currentColor" data-v-c287bfdc="" fill-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H14C14.5523 5 15 4.55228 15 4C15 3.44772 14.5523 3 14 3H3Z M3 7C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9H10C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7H3Z M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H7C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11H3Z M15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44771 13 8L13 13.5858L11.7071 12.2929C11.3166 11.9024 10.6834 11.9024 10.2929 12.2929C9.90237 12.6834 9.90237 13.3166 10.2929 13.7071L13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17C14.2652 17 14.5196 16.8946 14.7071 16.7071L17.7071 13.7071C18.0976 13.3166 18.0976 12.6834 17.7071 12.2929C17.3166 11.9024 16.6834 11.9024 16.2929 12.2929L15 13.5858L15 8Z" clip-rule="evenodd"></path></svg>
            <span>{{ $t("Unpin") }}</span>
          </a>
          <a
            href="#"
            @click.prevent="comment.is_spam ? markAsNotSpam(comment) : markAsSpam(comment)"
            class="flex items-center gap-1 text-neutral-700 text-sm hover:no-underline hover:text-project-500 transition duration-150"
          >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="14" height="14"><path fill="currentColor" d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16.212,8l-0.2,9h-2.024l-0.2-9 H16.212z M15.003,22.189c-0.828,0-1.323-0.441-1.323-1.182c0-0.755,0.494-1.196,1.323-1.196c0.822,0,1.316,0.441,1.316,1.196 C16.319,21.748,15.825,22.189,15.003,22.189z"></path></svg>
            <span>{{ comment.is_spam ? $t("Not spam") : $t("Spam") }}</span>
          </a>
        </div>
      </div>
    </div>

    <div class v-if="inputBoxShown">
      <div class="mt-4" :class="{ offset: parentCommentId === 0 }">
        <comment-input
          :post-id="postId"
          :board-slug="boardSlug"
          :comment-id="comment.id"
          :parent-comment-id="parentCommentId"
          :current-user="currentUser"
          :discussion-users="discussionUsers"
          ref="messageArea"
          @newNestedComment="hideInputBox()"
          :id="'reply-comment-input-container' + comment.id"
          :current-project-admin="currentProjectAdmin"
        ></comment-input>
      </div>
    </div>
    <dialog-model
      v-if="confirm_delete"
      :title="$t('Delete Comment')"
      :button-text="$t('Delete')"
      :message="$t('Are you sure you want to delete your comment?')"
      @done="commentDelete"
      @cancel="commentCancel"
    ></dialog-model>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import { formatDistance } from "date-fns";
import CommentInput from "./comment_input.vue";
import EventBus from "./event_bus";
import trashIcon from "images/trash.svg";
import { linkifyOpts } from "./variables";
import datefnLocales from "./locales/datefnlocales";

export default {
  props: [
    "original-comment",
    "discussion-users",
    "post-id",
    "current-user",
    "current-project-admin",
    "parent-comment-id",
    "board-slug",
    "showPin",
    "nestedshowPin",
    "projectName",
  ],

  data: function () {
    return {
      inputBoxShown: false,
      comment: this.originalComment,
      linkifyOpts,
      trashIcon,
      formatDistance,
      screenWidth: null,
      edit_input_show: false,
      confirm_delete: false,
      commentData: "",
      datefnLocales,
    };
  },
  components: {
    Avatar,
    CommentInput,
    trashIcon,
  },
  computed: {
    showPinButton() {
      if (this.showPin == undefined) return this.nestedshowPin;
      else return this.showPin;
    },
    getLocale() {
      return this.$root.$i18n.locale == "zh-CN"
        ? "zhCN"
        : this.$root.$i18n.locale;
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
  },
  methods: {
    showInputBox: function () {
      if (this.currentUser) {
        this.inputBoxShown = true;
        this.$nextTick(() => {
          this.$refs.messageArea.$refs.messageAreaInput.focus();
          this.$refs.messageArea.$refs.messageAreaInput.innerHTML =
            '<span contenteditable="false" class="mention">@' +
            this.comment.user.name +
            "</span>&nbsp;";
          document.execCommand("selectAll", false, null);
          document.getSelection().collapseToEnd();
        });
      } else {
        EventBus.$emit("openRegModal");
      }
    },
    hideInputBox: function () {
      this.inputBoxShown = false;
    },
    showDeleteDialog: function (comment) {
      this.commentData = comment;
      this.confirm_delete = true;
    },
    commentDelete: function () {
      this.deleteComment(this.commentData);
    },
    commentCancel: function () {
      this.confirm_delete = false;
    },
    deleteComment: function (comment) {
      var delUrl =
        "/boards/" +
        this.boardSlug +
        "/posts/" +
        this.postId +
        "/comments/" +
        comment.id;
      Rails.ajax({
        url: delUrl,
        type: "DELETE",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log("Deleted", data);
          this.$emit("updateComments", data.comments);
          this.confirm_delete = false;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    editShow: function () {
      this.edit_input_show = true;
    },
    editHide: function (data) {
      this.edit_input_show = data;
    },
    updateCommentData: function (data) {
      this.comment = data;
      this.edit_input_show = false;
    },
    pinComment: function (comment) {
      var data = new FormData();
      comment.pin = !comment.pin;
      data.append("comment[pin]", comment.pin);

      var pinUrl =
        "/boards/" +
        this.boardSlug +
        "/posts/" +
        this.postId +
        "/comments/" +
        comment.id +
        "/pin_to_top";
      Rails.ajax({
        url: pinUrl,
        type: "PUT",
        data: data,
        dataType: "json",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          data && this.$emit("updateComments", data);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    markAsSpam: function (comment) {
      this.toggleSpamStatus(comment, true);
    },
    markAsNotSpam: function (comment) {
      this.toggleSpamStatus(comment, false);
    },
    toggleSpamStatus: function (comment, isSpam) {
      var data = new FormData();
      data.append("comment[is_spam]", isSpam);

      var spamUrl =
        "/boards/" +
        this.boardSlug +
        "/posts/" +
        this.postId +
        "/comments/" +
        comment.id +
        "/toggle_spam";

      Rails.ajax({
        url: spamUrl,
        type: "PUT",
        data: data,
        dataType: "json",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if (data) {
            comment.is_spam = isSpam;
            this.$emit("updateComments", data);
          }
        },
        error: (data, status) => {
          console.log("Error toggling spam status:", data);
        },
      });
    },
  },
  filters: {
    highlightMentions: function (text) {
      var pattern = /\B@[a-z0-9_-]+/gi;
      var replaced_text = text.replace(pattern, "<strong>$&</strong>");
      var pattern = /\@.+?\;/g;
      var replaced_text = text.replace(pattern, "<strong>$&</strong>");

      return replaced_text;
    },
  },
};
</script>

<style scoped>
.offset {
  margin-left: calc(36px + 1rem);
}
@media only screen and (max-width: 500px) and (min-width: 320px) {
  .offset {
    margin-left: 0px;
  }
}

.unpin_postion {
  justify-content: space-between;
  display: flex;
}

.showPin {
  display: none;
}
.editComment {
  padding-bottom: 15px;
}
.showPinTop {
  display: block;
}
@media only screen and (max-width: 367px) {
  .comment-feature {
    font-size: 0.65rem;
  }
}
@media only screen and (max-width: 500px) {
  .editComment {
    margin-left: 52px;
  }
}
@media only screen and (min-width: 500px) {
  .editComment {
    margin-left: 52px;
  }
}
@media only screen and (max-width: 600px) {
  .showPin {
    display: block;
  }

  .showPinTop {
    display: none;
  }
}
</style>
<style>
.tippy-tooltip.light-theme {
  box-shadow: 0 0 7px 4px rgb(154 161 177 / 15%),
    0 0px 2px 0px rgb(36 40 47 / 25%), 0 4px 4px -2px rgb(91 94 105 / 15%) !important;
}
.tippy-tooltip {
  padding: 0.5rem 0.5625rem !important;
}

.comment__avatar--admin {
  position: relative;
}

.comment__avatar--admin::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 16px;
  height: 16px;
  background: white;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='star' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path' fill='%23FFC857' fill-rule='nonzero' points='8 0.960938 7.539063 2.054688 5.953125 5.820313 0.992188 6.359375 4.703125 9.859375 3.648438 15.023438 8 12.375 12.347656 15.023438 11.296875 9.859375 15.003906 6.359375 10.046875 5.820313'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
  border-radius: 50%;
  background-position: center;
  background-size: 85%;
  @apply shadow-md;
}
</style>
