<template>
  <div class="translation-setting">
    <p class="font-bold text-xl leading-6 text-gray-700 mb-5">Translations</p>
    <p class="font-bold text-lg text-gray-700 mb-1">Language</p>
    <p class="font-normal leading-5 text-gray-700 mb-2">
      Select the language you want your project to be displayed in.
    </p>
    <Select2
      class="mb-2 translation-select-2"
      v-model="locale"
      :settings="{
        width: '400px',
        theme: 'classic',
        placeholder: 'Select Language',
      }"
      :options="options"
    />
    <button
      class="btn-main w-32 flex justify-center rounded-lg mb-4"
      @click="changeLocale()"
    >
      Save
    </button>
    <p class="font-normal text-gray-700 mb-4 w-9/12">
      Certain elements of your project are customizable, you will want to
      manually update those to the selected language. Here is a list of the
      areas you will want to update:
    </p>
    <div class="pl-5">
      <ul class="font-normal text-project-500 mt-4 list-disc">
        <li v-for="board in boards" :key="board.name">
          <a :href="board.url" target="_blank">{{ board.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Select2 from "v-select2-component";

export default {
  name: "translation_setting",
  data() {
    return {
      locale: "",
      options: [
        { id: "ar", text: "Arabic" },
        { id: "zh-CN", text: "Chinese (Simplified)" },
        { id: "zh-TW", text: "Chinese (Traditional)" },
        { id: "nl", text: "Dutch" },
        { id: "en", text: "English" },
        { id: "fr", text: "French" },
        { id: "de", text: "German" },
        { id: "it", text: "Italian" },
        { id: "pt", text: "Portuguese" },
        { id: "pt-BR", text: "Portuguese (Brazil)" },
        { id: "es", text: "Spanish" },
      ],
      boards: [],
    };
  },
  components: {
    Select2,
  },
  created() {
    Rails.ajax({
      url: "/locale_settings",
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        this.locale = data.current_locale;
        this.boards = data.boards;
      },
      error: (data, status) => {
        this.error = data;
      },
    });
  },
  methods: {
    changeLocale() {
      let data = new FormData();
      data.append("project_locale", this.locale);
      Rails.ajax({
        url: "/locale_settings",
        type: "POST",
        data: data,
        dataType: "json",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log(data);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
  },
};
</script>

<style lang="scss">
.translation-select-2 {
  .dropdown-wrapper {
    display: none !important;
  }

  .select2-container--classic .select2-selection--single {
    height: 45px;
    border: 1px solid #dddddd;
    background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
  }

  .select2-container--classic
    .select2-selection--single
    .select2-selection__rendered {
    height: 45px;
    padding: 14px 13px;
    font-size: 14px;
    line-height: 14px;
  }

  .select2-container--classic
    .select2-selection--single
    .select2-selection__arrow {
    height: 44px;
    background: none;
    border-left: 0px;
  }

  @media screen and (max-width: 498px) {
    .select2-container {
      width: 280px !important;
    }
  }
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #ededed;
  color: black;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #ddd;
}
</style>
