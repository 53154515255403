<template>
  <div class="border border-gray-300 rounded-lg">
    <div class="flex flex-col m-4">
      <div class="h-12 flex flex-row justify-between">
        <div>
          <img
            src="../../../assets/images/logos/zapier.png"
            class="h-9 w-9"
            style="max-width: none"
          />
        </div>
        <a
          class="btn-main h-10 bg-gray-200 hover:bg-project-500 text-gray-700 text-sm hover:text-white border-gray-200 shadow-none hover:border-project-500 rounded"
          :href="this.isDisabled ? '#' : 'https://zapier.com/developer/public-invite/156735/fef123a71beedfbaf5603313627fb92e/'"
          :target="this.isDisabled ? '' : '_blank'"
        >
          Connect
        </a>
      </div>
      <div class="mt-4">
        <h4 class="font-bold text-neutral-800 text-base">Zapier</h4>
        <p class="text-sm mt-2 text-gray-500">
          Build custom automations and integrations with other apps
        </p>
      </div>
      <p class="text-xs font-medium mt-2 text-neutral-700">
        Your API key:
        <span class="font-normal font-mono text-neutral-900 text-sm">{{
          zApiKey
        }}</span>
      </p>
    </div>

    <Dialog title="Zapier" v-model="dialogVisible">
      <h4>Slack Settings</h4>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "../integration_dialog";
export default {
  props: ["zApiKey", "isDisabled"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
    },
  },
};
</script>
