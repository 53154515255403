<template>
  <div id="import-posts-container">
    <!--<div v-if="records" id="import-success-message" class="container button--green text-white rounded-xl shadow px-5 py-4 mt-4 mb-5">
      <span class="block text-center"> {{records}} Ideas Imported Successfully! </span>
    </div>-->
	<div class="container bg-white rounded-xl border px-5 py-4 mb-20 lg:mb-0">
		<div class="text-lg font-bold text-dblue-500">Import</div>
		<div class="whitespace-pre-line mt-2 text-neutral-800">Use the fields below to import data form third-party platforms into your board. You can import ideas, comments and upvotes one at a time, or all of them at once. Make sure to check the .CSV file samples provided for each type of data and use them as reference to prepare your import file, if the information is incorrect the import will fail.</div>
		<div class="mt-2 mb-1 tabs-border flex flex-row align-baseline">
			<button @click="ideaTab" value=1 class="p-3 active-tab dim-text" id="idea-tab" >Ideas</button>
			<button @click="commentTab" value=2 class="p-3 dim-text" id="comment-tab">Comments</button>
			<button @click="upvoteTab" value=3 class="p-3 dim-text" id="upvote-tab">Upvotes</button>
		</div>
		<div>
		<div class="whitespace-pre-line mt-5">Upload your .CSV to populate your board with <span id="dynamic-text">ideas</span>. You can download a sample .CSV file <span><a href="/files/idea-import.csv" target="_blank" class="download-template-link dynamic-href" id="download-link">clicking here</a>.</span></div>
		</div>
		<div id="import-post-download">
        <!-- <a href="/files/post_import_template.csv" target="_blank" class="download-template-link" id="download-link">Click here</a>
        to download post import template. -->
      </div>
      <div
          enctype="multipart/form-data"
          accept-charset="UTF-8"
          class="flex mt-5 flex-col justify-space-around"
		  id="custom-designs"
      >
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="authenticity_token" :value="this.form_token" />
		  <div class="wrapper">
			<div class="file-upload">
				<div>
					<p class="text-neutral-800">Choose file</p>
				</div>
          		<input type="file" name="csv" @change="getFile" id='upload-file' ref="csvFile" />
			</div>
			<span class="ml-2 dim-text" id='filename'>No file selected</span>
		  </div>
		  <div class="progress">
			<div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
		  </div>
		  <div class="form-check aggrement-check">
			<input class="form-check-input" type="checkbox" value="" id="aggrement-input">
			<label class="form-check-label dim-text" for="defaultCheck1">
				I confirm I have the user's consent for the use of their data and permission to contact them
			</label>
			<p id="aggrement-error"></p>
		  </div>
          <button
              name="Import"
              class="btn-main mt-4 text-center justify-center"
              v-if="user_signed_in"
              @click="importCSV"
              :style="{maxWidth:'190px'}"
              id="import-post-btn"
              >
              <svg v-if="isLoading" class="animate-spin h-15 w-5 mr-3 " viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Import
          </button>
      </div>
      <div v-if="errors" class="mt-5" id="import-posts-error">
        <div class="mb-5 bg-yellow-100 border border-yellow-400 p-4 rounded-xl mt-5 text-yellow-900 text-sm">
          <p><strong>There were errors while importing the CSV</strong></p>
          <p>Please remember:</p>
          <ul class="suggestions">
            <li>Use double quotes in fields that contains commas</li>
            <li>When double quotes are required use them to open and close a content field</li>
            <li>In case of doubts in the format, please <a href="/files/idea-import.csv" class="download-template-link dynamic-href" target="_blank">clicking here</a> to download the example</li>
          </ul>
        </div>
        <span class="text-lg font-bold text-dblue-500 mt-5">Idea import errors</span>
        <p  v-for="key in Object.keys(errors)" :key="key">
          {{ errors[key] }}
        </p>
        <div class="flex mt-5 flex-row justify-between" id="posts-faild-records">
          <button
              name="Import"
              class="btn-main text-center block"
              v-if="user_signed_in && this.failed_records"
              @click="downloadFailedRecords"
              >
              Download Failed Records
          </button>
        </div>
      </div>
	</div>
  </div>
</template>

<script>
export default {
  props: ["user_signed_in","action","token"],
  data: function () {
    return {
      form_token: this.token,
      form_action: this.action,
      file: null,
      errors:null,
      records:null,
      failed_records: null,
      isLoading: false
    }
  },
  methods: {
    importCSV: function () {
		if(document.getElementById('aggrement-input').checked ) {
			this.isLoading = true;
      this.errors = null;
			let data = new FormData();
			data.append("csv", this.file);
			if(document.getElementById('idea-tab').classList.contains('active-tab')){
				data.append("idea", this.file);
			} else if(document.getElementById('comment-tab').classList.contains('active-tab')){
				data.append("comment", this.file);
			} else if(document.getElementById('upvote-tab').classList.contains('active-tab')){
				data.append("upvote", this.file);
			}
			Rails.ajax({
          url: this.action,
          type: "POST",
          data: data,
          dataType: "json",
          success: (data) => {
            this.isLoading =false
            this.errors = data.errors
            this.records = data.records_imported
            this.failed_records = data.failed_records
          },
          error: (data, status) => {
          this.isLoading =false
          console.log(data);
        },
		  });
		} else {
			document.getElementById('aggrement-error').innerHTML = "*please confirm first."
		}
    },
    downloadFailedRecords: function() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        ...this.failed_records.map(item => Object.values(item).join(","))
      ]
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "errors.csv");
      link.click();
    },
	ideaTab: function(e) {
		var link = document.getElementsByClassName('dynamic-href')[0]
		link.setAttribute("href","/files/idea-import.csv")
		// '/files/post_import_template.csv'
		e.target.classList.add('active-tab')
		document.getElementById('comment-tab').classList.remove('active-tab')
		document.getElementById('dynamic-text').innerHTML = "ideas"
		document.getElementById('upvote-tab').classList.remove('active-tab')
		document.getElementById('upload-file').value= null;
		var x = document.getElementById('filename');
		x.innerHTML = "No file selected"
	},
	commentTab: function(e) {
		var link = document.getElementsByClassName('dynamic-href')[0]
		link.setAttribute("href","/files/comment-import.csv")

		e.target.classList.add('active-tab')
		document.getElementById('dynamic-text').innerHTML = "comments"
		document.getElementById('idea-tab').classList.remove('active-tab')
		document.getElementById('upvote-tab').classList.remove('active-tab')
		document.getElementById('upload-file').value= null;
		var x = document.getElementById('filename');
		x.innerHTML = "No file selected"
	},
	upvoteTab: function(e) {
		var link = document.getElementsByClassName('dynamic-href')[0]
		link.setAttribute("href","/files/upvote-import.csv")

		e.target.classList.add('active-tab')
		document.getElementById('dynamic-text').innerHTML = "upvotes"
		document.getElementById('comment-tab').classList.remove('active-tab')
		document.getElementById('idea-tab').classList.remove('active-tab')
		document.getElementById('upload-file').value= null;
		var x = document.getElementById('filename');
		x.innerHTML = "No file selected"
	},
    getFile: function (){
      this.file = this.$refs.csvFile.files[0]
	  var file_name = this.file.name
	  var x = document.getElementById('filename');
	  x.innerHTML = file_name
	//   x.style.display = 'none'
	// //   document.getElementById('filename').innerHTML = file_name
	// const para = document.createElement("span");
	// para.innerText = file_name;
	// document.getElementsByClassName('file-upload').appendChild(para);
    document.getElementById('upload-file').value= null;
    }
  },
};
</script>
<style scoped>
  .download-template-link {
    text-decoration: underline;
    color: rebeccapurple;
  }
  .import-errors-alert {
    flex-direction: column;
    align-items: flex-start;
  }
  .import-errors-alert .suggestions{
    list-style-type: circle;
    margin-left: 25px;
  }
</style>
