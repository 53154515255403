<template>
  <div>
    <div class="label" >{{imageLable}}</div>
    <div class="flex flex-col">
      <div class="flex flex-row image_adjustment mb-1" v-if="imagePreview && (removeImage != 1)">
        <div>
          <img :id="'image'+id" :src="imagePreview" alt="" class="image-preview float-left" />
        </div>
        <div>

        <button
            type="button"
            class="text-red-600"
            v-on:click="$emit('deleteImg', id)"
            >
        <svg
          class="w-6 h-6 x"
          fill="#F84339"
          viewBox="0 0 15 15"
          id="svg-cross"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 7.5 1 C 3.914063 1 1 3.914063 1 7.5 C 1 11.085938 3.914063 14 7.5 14 C 11.085938 14 14 11.085938 14 7.5 C 14 3.914063 11.085938 1 7.5 1 Z M 10.207031 9.5 L 9.5 10.207031 L 7.5 8.207031 L 5.5 10.207031 L 4.792969 9.5 L 6.792969 7.5 L 4.792969 5.5 L 5.5 4.792969 L 7.5 6.792969 L 9.5 4.792969 L 10.207031 5.5 L 8.207031 7.5 Z"
          ></path>
        </svg>
           </button>
        </div>
      </div>
      <div class="upload_btn">

        <button
          type="button"
          class="inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-lg focus:outline-none text-xs btn-color"
          @click="upload_img"
          >
          {{buttonName}}
        </button>
        <input type="file" :name="name || imageName" @change="changeFile" :id="id" class="hidden">
      </div>
    </div>
  </div>
</template>

<script>
import trashIcon from "images/trash.svg";
export default {
  name:'ImageUploader',
  props: ['imageLable','image', 'removeImage', 'id', 'buttonName', 'name'],
  computed: {
    imagePreview() {
      if (!this.image) return null;
      if (typeof this.image === 'string') {
        if (this.image.includes("missing.png")) {
          return null;
        }
        return this.image;
      }
      if (this.image instanceof File || this.image instanceof Blob) {
        return URL.createObjectURL(this.image);
      }
      return null;
    },
    imageName () {
      return 'project['+this.id+']'
    }
  },
  components: {
    trashIcon,
  },
  methods: {
    changeFile(e) {
       this.$emit('changeImg',e, this.id)
    },
    upload_img(){
      document.getElementById(this.id).click()
    }
  },
}
</script>
<style>
.image-preview {
  max-height: 109px;
  border: 1px solid;
  border-color: #e6e6e6ba;
  border-radius: 7px;
}
.image_adjustment {
  max-width:260px;
}
.x {
  margin-left: -15px;
  margin-top: -10px;;
}
.upload_btn {
  margin-block-start: auto;
}
.btn-color {
  color: #4b5563;
  background-color: #d3d3d361;
}
#svg-cross {
  border-radius: 50%;
  background-color: #fff;
}
#svg-cross:hover {
  fill: #B22222;
}
</style>
