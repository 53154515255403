<template>
  <div>
    <a
      @click.prevent="castVote()"
      href="#"
      class="text-neutral-600 text-lg flex-shrink-0 hover:no-underline flex flex-col items-center justify-center mr-4 w-16 py-4 border border-neutral-200 rounded-xl h-full"
      :class="{
        'text-project-500': alreadyVoted,
      }"
    >
        <svg
        class="w-3 h-3 mb-2 rotate-180 transition-transform duration-200 ease-in-out"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        :class="{ '-translate-y-0.5': isAnimating }"
      >
        <path
          d="M15,23c-0.256,0-0.512-0.098-0.707-0.293l-10-10c-0.286-0.286-0.372-0.716-0.217-1.09C4.23,11.243,4.596,11,5,11h20 c0.404,0,0.77,0.243,0.924,0.617c0.155,0.374,0.069,0.804-0.217,1.09l-10,10C15.512,22.902,15.256,23,15,23z"
        ></path>
      </svg>
      <div
        class="font-bold leading-none text-base -mt-1 mb-1"
        v-if="!allowVoteCount || currentProjectAdmin"
        id="post-upvote-count"
      >
        {{ upvotes }}
        </div>
    </a>
  </div>
</template>

<script>
import EventBus from "event_bus";

export default {
  props: ["current-user", "original-post", 'allow-anonymous', "allow-vote-count", "current-project-admin"],
  data: function () {
    return {
      post: this.originalPost,
      upvotes:
        this.originalPost.cached_votes_up + this.originalPost.manual_upvotes,
      alreadyVoted: this.originalPost.current_user_voted_for,
      ip_address: "",
      isVoting: false,
      isAnimating: false,
    };
  },
  mounted () {
    EventBus.$on("updateUpvotes", (post) => {
      this.upvotes = post.cached_votes_up + post.manual_upvotes
    });
  },
  methods: {
    castVote: async function () {
      if (this.isVoting) return;
      
      // Handle unauthorized users
      if (!this.allowAnonymous && !this.currentUser) {
        EventBus.$emit("openRegModalSignup");
        return;
      }

      this.isVoting = true;
      // Only animate when adding a vote, not removing
      if (!this.alreadyVoted) {
        this.isAnimating = true;
        setTimeout(() => {
          this.isAnimating = false;
        }, 300);
      }

      // Optimistically update UI
      const previousVoteState = this.alreadyVoted;
      const voteChange = previousVoteState ? -1 : 1;
      this.alreadyVoted = !previousVoteState;
      this.upvotes += voteChange;

      try {
        // Get IP address only if needed
        if (!this.ip_address) {
          const response = await fetch('https://api.ipify.org?format=json');
          const { ip } = await response.json();
          this.ip_address = ip;
        }

        const vote_post_url = "/boards/" + this.post.board_id + "/posts/" + this.post.id + "/vote";
        const data = new FormData();
        data.append("ip_address", this.ip_address);

        const response = await new Promise((resolve, reject) => {
          Rails.ajax({
            url: vote_post_url,
            type: "POST",
            data: data,
            dataType: "json",
            success: resolve,
            error: reject,
          });
        });

        // Handle server response
        if (response.restricted_domain) {
          // Revert optimistic updates if domain is restricted
          this.alreadyVoted = previousVoteState;
          this.upvotes -= voteChange;
          return;
        }

        if (typeof response.votes !== 'undefined') {
          // Update with server's vote count
          this.upvotes = response.votes;
        }

      } catch (error) {
        // Revert optimistic updates on error
        this.alreadyVoted = previousVoteState;
        this.upvotes -= voteChange;
        console.error('Voting failed:', error);
      } finally {
        this.isVoting = false;
      }
    },
  },
};
</script>

<style scoped></style>
