<template>
  <div
    class="grid grid-cols-[auto_1fr] border rounded-xl hover:bg-neutral-50 transition duration-200 ease-in-out"
  >
    <a
      @click.prevent="castVote()"
      href="#"
      class="text-neutral-600 text-lg flex-shrink-0 hover:no-underline flex flex-col items-center justify-center border-r h-full w-16"
      :class="{
        'text-project-500': alreadyVoted,
      }"
    >
      <svg
        class="w-3 h-3 mb-2 rotate-180 transition-transform duration-200 ease-in-out"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        :class="{ '-translate-y-0.5': isAnimating }"
      >
        <path d="M15,23c-0.256,0-0.512-0.098-0.707-0.293l-10-10c-0.286-0.286-0.372-0.716-0.217-1.09C4.23,11.243,4.596,11,5,11h20 c0.404,0,0.77,0.243,0.924,0.617c0.155,0.374,0.069,0.804-0.217,1.09l-10,10C15.512,22.902,15.256,23,15,23z"></path>
      </svg>
      <div
        class="font-bold leading-none text-base -mt-1 mb-1"
        id="post-upvotes"
        v-if="!originalPost.board.allow_vote_count || currentProjectAdmin"
      >
        {{ upvotes }}
      </div>
    </a>

    <a
      :href="post.url"
      class="flex flex-col p-4 hover:no-underline" 
      id="roadmap-post-data">
      <div class="flex items-center">
        <a
          :href="post.url"
          id="post-title"
          class="text-dblue-500 font-medium hover:no-underline hover:text-project-500 transition duration-150 ease-in-out"
          >{{ post.title }}</a
        >
      </div>
      <div class="flex items-center gap-1 mt-1">
        <div v-if="post.is_private" class="mr-1 opacity-50 text-neutral-700">
          <simple-svg
          :filepath="privateIcon"
          width="16px"
          height="16px"
          />
        </div>
      <span class="text-xs text-dblue-400" id="post-board">{{
        post.board.name
        }}</span>
      </div>
    </a>
  </div>
</template>

<script>
import EventBus from "event_bus";
import privateIcon from "images/icons/eye-slash.svg";

export default {
  props: [
    "original-upvotes",
    "current-user",
    "original-post",
    "allow-anonymous",
    "current-project-admin",
  ],
  data: function () {
    return {
      post: this.originalPost,
      upvotes:
        this.originalPost.cached_votes_up + this.originalPost.manual_upvotes,
      alreadyVoted: this.originalPost.current_user_voted_for,
      lines: 1,
      ip_address: "",
      privateIcon,
      isVoting: false,
      isAnimating: false,
    };
  },
  components: { privateIcon },
  methods: {
    castVote: async function () {
      if (this.isVoting) return;
      
      if (!this.currentUser) {
        const lastVoteTime = localStorage.getItem('lastVoteTime_' + this.post.id);
        const currentTime = new Date().getTime();
        if (lastVoteTime && currentTime - lastVoteTime < 1000) {
          return;
        }
      }

      if (!this.allowAnonymous && !this.currentUser) {
        EventBus.$emit("openRegModalSignup");
        return;
      }

      this.isVoting = true;
      
      if (!this.alreadyVoted) {
        this.isAnimating = true;
        setTimeout(() => {
          this.isAnimating = false;
        }, 300);
      }

      const previousVoteState = this.alreadyVoted;
      const voteChange = previousVoteState ? -1 : 1;
      this.alreadyVoted = !previousVoteState;
      this.upvotes += voteChange;

      try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const { ip } = await ipResponse.json();
        
        const vote_post_url = "/boards/" + this.post.board_id + "/posts/" + this.post.id + "/vote";
        const data = new FormData();
        data.append("ip_address", ip);

        const response = await new Promise((resolve, reject) => {
          Rails.ajax({
            url: vote_post_url,
            type: "POST",
            data: data,
            dataType: "json",
            success: resolve,
            error: reject,
          });
        });

        const responseData = typeof response === 'string' ? JSON.parse(response) : response;
        
        if (response.status === 'unprocessable_entity') {
          this.alreadyVoted = previousVoteState;
          this.upvotes -= voteChange;
          throw new Error("Vote failed: " + response.message);
        }

        if (typeof responseData.votes !== 'undefined') {
          this.upvotes = responseData.votes;
          EventBus.$emit("postRoadmapVotes", {
            id: this.post.id,
            total: responseData.votes,
          });

          if (!this.currentUser) {
            localStorage.setItem('lastVoteTime_' + this.post.id, new Date().getTime());
          }
        }
      } catch (error) {
        this.alreadyVoted = previousVoteState;
        this.upvotes -= voteChange;
        console.error('Voting failed:', error);
      } finally {
        this.isVoting = false;
      }
    },
  },
};
</script>

<style scoped></style>
