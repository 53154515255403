<template>
  <div>
    <div class= "mt-4 flex justify-between items-center rounded-xl cursor-move" :class="deleteTransition ? 'deleteTransitionHidden' : '' " id="show-status">
      <span class="handle" >
        <div id="edit-status-draggable-icon">
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path></svg>
        </div>
      </span>
      <div class="ml-3 swatchDiv" id="edit-status-color">
        <v-swatches
          v-model="newColor"
          :swatches="swatches"
          show-fallback
          fallback-ok-class="swatchOkBtn"
          :wrapper-style="this.screenWidth < 400 ? {width: '160px'} : {width: '265px'}"
          fallback-input-class="swacthInputField"
          fallback-ok-text="Save"
        >
          <div
            slot="trigger"
            class="color_div flex items-end justify-end"
            :style="{ backgroundColor: newColor }"
            @click="focusOut"
          >
          </div>
        </v-swatches>
      </div>
      <div class="status_input" id="edit-status-input-container">
        <div class="mr-4 pl-2 flex">
          <div v-if='screenWidth < 450' class="showStatus" id="edit-status-show-name">
            <span class="labels__item__name " v-if="!inputHide && !hidekeyDel">
              <a
                href="#"
                key="label"
                class="labels__item-inside showStatusName p-2.5"
                @click.prevent="changeHide()"
              >
                <span v-if="!hidekeyDel">
                  <span v-if="label.name.length<19">{{ label.name }}</span>
                  <span v-else>{{ label.name.substring(0,19)+".." }}</span>
                </span>
              </a>
            </span>
            <input
            type="text"
            class="input newNameInput"
            v-model="newName"
            @keyup.enter="handleSave()"
            placeholder="Status name"
            :ref="'input'+label.id"
            v-else-if="inputHide"
            @focusout="focusOut"
            id="new_status_id"
            />
          </div>
          <div v-else class="showStatus" id="edit-status-show-name">
            <span  v-if="!inputHide">
              <div class="status_name">
                <a
                  href="#"
                  key="label"
                  class="labels__item-inside showStatusName p-2.5"
                  @click.prevent="changeHide()"

                >
                  <span v-if="!hidekeyDel">
                    <span v-if="label.name.length<25">{{ label.name }}</span>
                    <span v-else>{{ label.name.substring(0,25)+".." }}</span>
                  </span>

                  <span v-else>
                    <span v-if="label.name.length<11">{{ label.name }}</span>
                    <span v-else>{{ label.name.substring(0,11)+".." }}</span>
                  </span>
                </a>
              </div>
            </span>
            <input
            type="text"
            class="input newNameInput"
            v-model="newName"
            @keyup.enter="handleSave()"
            placeholder="Status name"
            :ref="'input'+label.id"
            v-else-if="inputHide"
            @focusout="focusOut"
            id="new_status_id"
            />
          </div>
        </div>
      </div>

      <div v-if="!label.act_as_done" id="edit-status-delete-btn">
        <a
          href="#"
          class="text-neutral-800"
          @mousedown.prevent="delHide()"
          v-if="!hidekeyDel"
          type="button"
        >
         <span class="delete-btn-flat">
          <simple-svg
            :filepath="trashIcon"
            :width="'20px'"
            :height="'20px'"
          />
        </span>
        </a>

        <p class="labels__item__controls__block" v-else-if="hidekeyDel">
          <a
            href="#"
            @click.prevent="destroyLabel()"
            class="btn-flat text-sm py-1 mr-2 flex items-center deleteBtnClr"
          >
          <span class="deleteBtn text-neutral-800" >{{ $t("Delete") }}</span>
          </a>
          <a
            href="#"
            @click.prevent="cancel()"
            class="btn-flat text-sm py-1 flex items-center cancelBtnParent"
            ><span class="text-neutral-800 cancelBtn" >{{ $t("Cancel") }}</span></a
          >
        </p>
      </div>
    </div>
    <div
      v-if="error"
      class="error_show mt-2"
      id="edit-status-error"
      >{{this.error}}
    </div>
  </div>
</template>

<script>
import editIcon from "../images/icons/edit.svg";
import trashIcon from "../images/icons/trash.svg";
import questionIcon from "../images/icons/question-circle.svg";
import EventBus from "event_bus"
import VSwatches from "vue-swatches";

export default {
  props: ["original-label", "project-id", "input-hide", "hidekeyDel", "status-name"],

  data: function() {
    return {
      label: this.originalLabel,
      newName: this.originalLabel.name,
      newColor: this.originalLabel.color,
      hideRoadmap: this.originalLabel.hide_roadmap,
      hideBoards: this.originalLabel.hide_boards,
      isLoading: false,
      editIcon,
      trashIcon,
      questionIcon,
      error: "",
      deleteTransition: false,
      screenWidth: null,
      focusremove: true,
      swatches: [
        "#2D80FA",
        "#18BFFF",
        "#1ECFC7",
        "#FF08C3",
        "#F92B60",
        "#FF702C",
        "#FDB402",
        "#20C933",
        "#8B46FF",
        "#666666"
      ]
    };
  },

  mounted: function() {
    window.addEventListener("keydown", e => {
      if (e.key == "esc") {
        this.labelsOpen = false;
      }
    });
    var fallbackWrapper = document.getElementsByClassName("vue-swatches__fallback__wrapper");
    var i;
    for (i = 0; i < fallbackWrapper.length; i++) {
      fallbackWrapper[i].style.width="92%"
    };
    this.screenWidth = window.innerWidth;
    EventBus.$on("focusremove", (focus) => {
      this.focusremove = focus;
    });
  },

  components: {
    editIcon,
    trashIcon,
    questionIcon,
    VSwatches
  },

  methods: {
    handleClose() {
      this.newName = this.label.name;
      this.newColor = this.label.color;
      this.hideRoadmap = this.label.hide_roadmap;
      this.hideBoards = this.label.hide_boards;
      this.error = "";
    },

    handleSave() {
      this.updateLabel();
    },

    focusOut() {
      this.$nextTick(()=> {
        if(this.newName !== this.label.name && this.focusremove){
          this.updateLabel();
        }
       else if(!this.focusremove ){
          this.$emit("changeHide",-1);
          this.newName = this.label.name;
          this.focusremove = true;
          this.error="";
          return
        }
        else if (this.focusremove){
          this.$emit("delHide", -1);
          this.$emit("changeHide", -1);
          this.newName = this.label.name;
          this.error="";
          return
        }
      })
    },

    delHide() {
      this.$emit("changeHide", -1);
      this.$emit("delHide", this.originalLabel.id);
    },

    changeHide() {
      this.error=""
      this.$emit("delHide", -1);
      this.$emit("changeHide", this.originalLabel.id);
      this.focusremove = true;
    },

    cancel() {
      this.error=""
      this.$emit("delHide", -1);
      this.$emit("changeHide", -1);
      this.focusremove = true;
    },

    destroyLabel() {
      this.deleteTransition = true;
      this.isLoading = true;
      setTimeout(() => {
        Rails.ajax({
        url: "/statuses/" + this.label.id,
        type: "DELETE",
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.deleteTransition = false;
          this.handleClose();
          this.$emit("destroyedLabel", data);
          this.isLoading = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        }
      }); }, 300);
    },

    updateLabel() {
      if(!this.newName || this.newName==this.statusName.not_set) return

      this.isLoading = true;

      let params = new FormData();
      if (this.originalLabel.name !== this.newName) {
        params.append("label[name]", this.newName);
        params.append("label[project_id]", this.projectId);
      }
      params.append("label[color]", this.newColor);
      params.append("label[hide_roadmap]", this.hideRoadmap);
      params.append("label[hide_boards]", this.hideBoards);
      Rails.ajax({
        url: "/statuses/" + this.label.id,
        type: "PATCH",
        data: params,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          if (data.error){
            this.error=data.error;
          }
          else{
            this.label.name = this.newName;
            this.label.color = this.newColor;
            this.label.hide_roadmap = this.hideRoadmap
            this.hide_boards = this.hideBoards
            this.$emit("updatedLabel", data);
          }
          this.isLoading = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        }
      });
    }
  },

  watch: {
    newColor: function() {
     this.handleSave()
    },
    inputHide: function (value) {
      this.$nextTick(()=> {
        value && this.$refs['input'+this.label.id].focus();
      })
    }
  }
};
</script>

<style scoped>

  .error_show {
    color: red;
    margin-left: 4.35rem;
    flex: inherit;
  }
  .deleteBtn {
    padding: 4px;
    color: white !important;
  }
  .deleteBtn:focus{
    outline:none !important;
  }
  .deleteBtnClr {
    background: #e04545 !important;
  }
  .deleteBtnClr:hover {
    color:white !important;
  }
  .deleteBtnClr:focus{
    outline:none !important;
  }
  .cancelBtn {
    padding: 4px;
  }
  .cancelBtn:focus {
    outline:none !important;
  }
  .cancelBtnParent:focus{
    outline:none !important;
  }
  .deleteTransitionHidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s linear;
  }
  .showStatusName {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 137.52%;
  }
  .showStatus {
    height: 43px;
    width: 100%;
  }
  .status_name:hover {
      text-decoration: none;
  }
  .status_name{
    height: 43px;
  }
  .status_name:hover {
    border-radius: 0.375rem;
    background: #ebecf0;
  }
</style>
