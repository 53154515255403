<template>
  <div class="mb-4 sso-webhook-style">
    <h3 class="text-xl font-bold text-neutral-800 relative mr-2 mb-4 flex-1">
      Webhooks
    </h3>
    <p>
      Webhooks will notify your server of new events in FeedBear as they happen, using HTTPS requests. This is useful in case you want to
      integrate FeedBear with other existing tools and services. Here is an example of how it works:
    </p>
    <div class="mt-3">
      <p>1. A user submits a idea to your board</p>
      <p>2. FeedBear sends a request to a URL that lives on your server, with all the idea information (e.g. title, content, date, user)</p>
      <p>3. You receive the HTTPS request, parse it, and can now use the idea information as you wish (e.g. raise a new card in Trello)</p>
    </div>
    <div class="mt-4">
      <h4 class="text-xm mt-3 font-bold text-neutral-900 mb-1">Verification Token</h4>
      <p>Below you can find your verification token, which will be included in all requests performed to your server. Make sure to validate it in order to confirm that the requests are being performed by FeedBear.</p>
      <div class="mt-2" :class="screenWidth < 768? '' : 'flex'">
        <div class="mr-2 relative flex justify-between items-center input inp-width">
          <div class="parentInput">
            <input
              v-model="webhookToken"
              class="childInput"
              :readonly="true"
            />
          </div>
          <tippy
            interactive
            :animate-fill="false"
            theme="translucent"
            animation="fade"
            trigger="click"
            arrow="roundArrow"
          >
            <template v-slot:trigger>
              <div @click="clipCopy" class="cursor-pointer">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg>
              </div>
           </template>
            <span>
              <p class="font-bold">COPIED!</p>
            </span>
          </tippy>
        </div>
        <div :class="screenWidth < 768? 'mt-3' : ''">
            <a
              href="#"
              class="btn-main flex justify-center whitespace-nowrap"
              @click.prevent="generateNewKeyPopup = true"
            >Generate new key
            </a>
          </div>
      </div>
    </div>
    <transition name="fromBottom" mode="out-in" appear>
      <div class="mt-4">
        <h4 class="text-xm mt-3 font-bold text-neutral-900 mb-1">Webhook URL</h4>
        <p>Whenever an action is triggered in FeedBear (e.g. a new idea is posted), we will send a request to the specified URL</p>
        <div class="mt-2" :class="screenWidth < 768? '' : 'flex'">
          <div class="relative mr-2 flex justify-between items-center inp-width input">
            <div class="parentInput">
              <input
                class="childInput"
                v-model="webhookUrl"
                placeholder="e.g. https://yoursite.com/feedbear-events"
              />
            </div>
            <div v-if="tick_url">
              <svg class="w-6 h-6 text-green-600 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
            </div>
          </div>
          <div class="btn-width" :class="screenWidth < 768? 'mt-3' : ''">
            <a
              v-if='showLoader'
              href="#"
              class="btn-flat flex justify-center"
            >
              <svg class="animate-spin h-6 w-5" viewBox="0 0 26 26">
                <circle class="opacity-0" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </a>
            <div v-else class="">
              <a
                v-if = "webhookUrl == null || webhookUrl == ''"
                href="#"
                class="btn-main flex justify-center"
                @click.prevent="webhookUrlSubmit(webhookUrl,'Webhook URL has been saved')"
              >Save URL
              </a>
              <a
                v-else
                href="#"
                class="btn-main flex justify-center"
                @click.prevent="webhookUrlSubmit(webhookUrl,'Webhook URL has been saved')"
              >Change URL
              </a>
            </div>
          </div>
        </div>
        <p class="text-base not-italic webhookErrorMsg" v-if="webhookErrorMessage && isWebhookEnabled">One or more requests to your server have failed, this is the error returned: {{this.webhookErrorMessage}}</p>
      </div>
    </transition>
    <div class="mt-4">
    <h4 class="text-xm mt-3 font-bold text-neutral-900 mb-1">Enable Webhooks</h4>
    <p>Before you can enable Webhooks make sure to provide the Webhook URL, which will be used for all requests</p>
    <div class="toggle-wrapper mt-2">
      <div
        class="button--toggle"
        :class="{ 'area--disabled': isPrivateToggleLoading }"
        @click="urlValidation()"
      >
        <div v-if="isDisabled" class="buttonToggle"></div>
        <input
          type="checkbox"
          class="checkbox"
          @change="toggleWebhookEnabled()"
          v-model="isWebhookEnabled"
          :disabled=isDisabled
        />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span v-if="!isPrivateToggleLoading" key="loading1">
          <span
            v-if="isWebhookEnabled"
            key="isprivate"
            class="flex items-center"
          >
            <span><strong>Webhooks Enabled</strong> – Requests are being performed to your server </span>
          </span>
          <span v-else key="ispublic" class="flex items-center">
            <span><strong>Webhooks Disabled</strong> – No requests are being made to your server </span>
          </span>
        </span>
        <span class="text-neutral-700" v-else key="loading2">Saving …</span>
      </transition>
    </div>
     <div class=" mt-5 p-4 rounded-lg messageEnabling" v-if="validationMessage">
      <p class=" text-base not-italic messageText">Please add a Webhook URL before enabling Webhooks</p>
    </div>
    </div>
    <WebhookConfig
      class="mt-8"
      :sub-domain="this.subDomain"
      :custom-domain="this.customDomain"
      >
    </WebhookConfig>
    <Dialog v-model="generateNewKeyPopup" title="Generate new key" custom-width-class="w-3/12">
      <div class="p-4 pt-0">
        <div class="p-2 pl-0">
          Are you sure you want to generate a new key?<br> Once a new key is
          generated your old key will be deleted, this is not reversible.
        </div>
        <div class="flex justify-start mt-2">
          <a
            href="#"
            class="btn-flat justify-center mr-2 whitespace-nowrap"
            @click.prevent="generateNewKeyPopup = false"
          >
            Cancel</a
          >
          <a
            href="#"
            class="btn-main justify-center mx-2 whitespace-nowrap"
            @click.prevent="regenerateToken"
          >
            Generate new key</a
          >
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import WebhookConfig from '../../components/webhook_config.vue'
import Dialog from "../../components/dialog";

export default {
  components: {
    WebhookConfig,
    Dialog,
  },
  props:{
    subDomain:String,
    customDomain: String,
    projectWebhookEnabled: Boolean,
    projectWebhookToken: String,
    projectWebhookUrl: String,
    projectUpdateUrl: String,
    webhookErrorMessage: String
  },
  data() {
    return {
      webhookUrl: this.projectWebhookUrl,
      showLoader: false,
      isPrivateToggleLoading: false,
      isWebhookEnabled: this.projectWebhookEnabled,
      tick_url: false,
      validationMessage: false,
      screenWidth: '',
      webhookToken: this.projectWebhookToken,
      generateNewKeyPopup: false,
    }
  },
  computed: {
    isDisabled() {
      if (this.isWebhookEnabled) {
        return false;
      }
      return !this.tick_url;
    }
  },
  mounted () {
    if (this.webhookUrl) {
      this.tick_url = true;
    }
    this.screenWidth = window.innerWidth;
  },
  methods: {
    regenerateToken() {
      this.generateNewKeyPopup = false;
      var data = new FormData();
      data.append("project[token_type]", "webhook_token");
      Rails.ajax({
        url: this.projectUpdateUrl,
        type: "PATCH",
        data: data,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.webhookToken = data.webhook_token;
          this.$notify({
            group: 'success',
            text: 'New FeedBear webhook verification token is generated successfully',
          });
        },
        error: (status) => {
          this.$notify({
            group: 'alert',
            text: data,
          });
        }
      });
    },
    urlValidation() {
      this.validationMessage = this.isDisabled;
    },
    webhookUrlSubmit(redirect_url,msg) {
      var data = new FormData();
      data.append("project[webhook_url]", redirect_url);
      this.validationMessage = false;
      this.showLoader = true;
      Rails.ajax({
        url: this.projectUpdateUrl,
        type: "PATCH",
        data: data,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.showLoader = false;
          this.webhookUrl = data.project.webhook_url;
          this.tick_url = true;
          this.$emit("webhookUrlUpdate", data.project.webhook_url);
          this.$notify({
            group: 'success',
            text: msg,
          });
        },
        error: (data, status) => {
          this.showLoader = false;
          this.tick_url = false;
          this.$notify({
            group: 'alert',
            text: data,
          });
        }
      });
    },
    toggleWebhookEnabled() {
      var data = new FormData();
      data.append("project[webhook_enabled]", this.isWebhookEnabled);
      Rails.ajax({
        url: this.projectUpdateUrl,
        type: "Patch",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isWebhookEnabled = data.project.webhook_enabled;
          this.$emit("webhookEnabledUpdate", this.isWebhookEnabled);
          this.$notify({
            group: data.project.webhook_enabled? 'success' : 'alert',
            text: data.project.webhook_enabled? "Webhook has been enabled successfully" : "Webhook has been disabled",
          });
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    clipCopy () {
      navigator.clipboard.writeText(this.webhookToken);
    },
  },
};
</script>

<style scoped>

</style>
