<template>
  <div>
    <h3 class="text-xl font-bold text-neutral-800">Menu</h3>
    <p class="text-neutral-800 mb-4">
      Configure the links that are displayed in your top menu.
    </p>
    <form class="w-full" @submit.prevent="handleSubmit()" method="post">
      <div
        id="table-container"
        class="box-border bg-white border border-gray-300 border-solid rounded-lg p-4 max-w-[720px]"
      >
        <draggable v-model="projectMenus" tag="div" handle=".handle" :animation="200" ghost-class="ghost-card" class="w-full draggable-menu-box">
          <div v-for="(item, index) in projectMenus" :key="item.id" class="flex items-center p-3 focus:outline-none text-sm border border-neutral-300 rounded-lg bg-gradient-to-b from-white to-neutral-100 shadow-sm text-dblue-500 focus:to-white focus:border-neutral-400 mb-3">
            <div class="pb-0 w-8 flex-shrink-0" style="width: 26px!important;">
              <span class="handle cursor-move">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path></svg>
              </span>
            </div>
            <div class="flex-grow flex gap-3">
              <div class="flex-grow">
                <input
                  :value="menuItems[`${item.id}`]['label']"
                  @input="updateLabel(item.id, $event.target.value)"
                  :id="`project_menu_items_attributes_${item.id}_label`"
                  type="text"
                  class="input w-full"
                  :placeholder="menuItems[`${item.id}`]['link_type'] === 'boards' ? 'Boards' : 'Label'"
                  :name="`project[menu_items_attributes][${item.id}][label]`"
                  :disabled="menuItems[`${item.id}`]['link_type'] === 'boards'"
                />
              </div>
              <div class="flex-grow">
                <div class="relative">
                  <select
                    v-model="menuItems[`${item.id}`]['link_type']"
                    :id="`project_menu_items_attributes_${item.id}_link_type`"
                    class="input select w-full"
                    :name="`project[menu_items_attributes][${item.id}][link_type]`"
                    @change="resetAndDisableFields($event, `${item.id}`)"
                  >
                    <option
                      v-for="option in linkTypes"
                      :key="option[0]"
                      :value="option[0]"
                      :selected="option[0] === item.link_type"
                      :disabled="(option[0] === 'roadmap' && roadmapEnabled == false) || (option[0] === 'changelog' && changelogEnabled == false)"
                    >
                      {{ option[1] }}
                    </option>
                  </select>
                  <svg
                    class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="flex-grow">
                <input
                  :value="['boards', 'changelog', 'roadmap'].includes(menuItems[`${item.id}`]['link_type']) ? '' : menuItems[`${item.id}`]['link_url']"
                  @input="updateLinkUrl(item.id, $event.target.value)"
                  :id="`project_menu_items_attributes_${item.id}_link_url`"
                  type="text"
                  class="input w-full"
                  :placeholder="getLinkPlaceholder(menuItems[`${item.id}`]['link_type'])"
                  :name="`project[menu_items_attributes][${item.id}][link_url]`"
                  :disabled="['boards', 'changelog', 'roadmap'].includes(menuItems[`${item.id}`]['link_type'])"
                />
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <div 
      v-if="boards.length > 1"
      class="my-10">
      <h4 class="text-lg font-bold text-neutral-800">
        Board Order
      </h4>
      <p class="text-neutral-800 mb-4">
        Drag and drop the boards to change the order in which they appear in the
        top menu.
      </p>
      <div class="border border-gray-300 border-solid rounded-lg p-4 max-w-[720px]">
        <draggable
          :list="boards"
          :animation="200"
          ghost-class="moving-card"
          class="w-full draggable-board-box"
          @start="drag=true"
          @end="drag=false"
        >
          <transition-group>
            <template v-for="board in boards">
              <div :key="board.id" class="flex items-center gap-4 p-3 focus:outline-none text-sm border border-neutral-300 rounded-lg bg-gradient-to-b from-white to-neutral-100 shadow-sm text-dblue-500 focus:to-white focus:border-neutral-400">
                <span class="handle cursor-move">
                  <div id="edit-status-draggable-icon">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path></svg>
                  </div>
                </span>
                <div class="text-base leading-5 text-neutral-800">
                  {{ board.name }}
                </div>
              </div>
            </template>
          </transition-group>
        </draggable>
      </div>
      </div>
      <input
        type="submit"
        value="Save"
        class="btn-main mt-6 h-10 font-bold text-base leading-5"
      />
    </form>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "menus",
  props: ["project-menus", "roadmap-enabled", "changelog-enabled", "boards"],

  data() {
    return {
      linkTypes: [
        ["boards", "Boards"],
        ["changelog", "Changelog"],
        ["roadmap", "Roadmap"],
        ["custom_link", "Custom link"],
      ],
      menuItems: {},
    };
  },
  created() { 
    this.menuItems = Object.fromEntries(
      this.projectMenus.map((menu) => [
        menu.id,
        {
          label: menu.link_type === 'boards' ? 'Boards' : menu.label,
          link_type: menu.link_type,
          link_url: menu.link_url,
        },
      ])
    );
    this.boards.sort((a, b) => {
      if (a.order_number === null && b.order_number === null) {
        return 0;
      }
      if (a.order_number === null) {
        return 1;
      }
      if (b.order_number === null) {
        return -1;
      }
      return a.order_number - b.order_number;
    });
  },
  components: {
    draggable,
  },
  methods: {
    updateLabel(id, value) {
      if (this.menuItems[id]['link_type'] !== 'boards') {
        this.menuItems[id].label = value;
      }
    },

    updateLinkUrl(id, value) {
      if (!['boards', 'changelog', 'roadmap'].includes(this.menuItems[id]['link_type'])) {
        this.menuItems[id].link_url = value;
      }
    },

    resetAndDisableFields(event, id) {
      var val = event.target.value;
      if (val === 'boards') {
        this.menuItems[id].label = '';
        this.menuItems[id].link_url = '';
      } else if (val === 'roadmap') {
        this.menuItems[id].label = "Roadmap";
        this.menuItems[id].link_url = '';
      } else if (val === 'changelog') {
        this.menuItems[id].label = "Changelog";
        this.menuItems[id].link_url = '';
      } else {
        // For custom links, reset the label and link_url if they were previously set to default values
        if (['', 'Roadmap', 'Changelog'].includes(this.menuItems[id].label)) {
          this.menuItems[id].label = '';
        }
        if (['', '/roadmap', '/updates'].includes(this.menuItems[id].link_url)) {
          this.menuItems[id].link_url = '';
        }
      }
    },
    handleSubmit() {
      this.sortingMenuItems();
      this.sortingBoards();

      let formData = new FormData();
      formData.append("menu_items", JSON.stringify(this.menuItems));

      Rails.ajax({
        url: "/projects/project_menus",
        type: "PATCH",
        data: formData,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {},
        error: (data, status) => {},
      });
    },
    sortingBoards() {
      let boardIds = this.boards.map((board) => board.id);
      let params = new FormData();
      params.append("board_ids", boardIds);

      Rails.ajax({
        url: "/projects/sort_boards",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {},
        error: (data, status) => {},
      });
    },
    sortingMenuItems() {
      let menuItemIds = this.projectMenus.map((item) => item.id);
      let params = new FormData();
      params.append("menu_item_ids", JSON.stringify(menuItemIds));
      Rails.ajax({
        url: "/projects/sort_menu_items",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          // Optionally handle success
        },
        error: (data, status) => {
          // Optionally handle error
        },
      });
    },
    getLinkPlaceholder(linkType) {
      switch (linkType) {
        case 'boards':
          return 'Boards dropdown';
        case 'roadmap':
          return '/roadmap';
        case 'changelog':
          return '/updates';
        default:
          return 'https://';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#table-container {
  width: 100%;
  max-width: 720px;
  
  .draggable-menu-box > div:last-child {
    margin-bottom: 0;
  }
  
  input:disabled {
    background-color: #f3f3f3;
  }
}

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
}

.handle {
  cursor: move;
}

.draggable-menu-box {
  display: flex;
  flex-direction: column;
}

// Add this new style for the gap
.flex.gap-3 {
  gap: 0.75rem; // This is equivalent to the Tailwind 'gap-3' class
}

input:disabled::placeholder {
  color: #6b7280; // Adjust this color to match your design
  opacity: 1; // Makes sure the placeholder is fully visible
}
</style>