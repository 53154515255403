import { render, staticRenderFns } from "./idea_not_found.vue?vue&type=template&id=e92112f8&scoped=true&"
import script from "./idea_not_found.vue?vue&type=script&lang=js&"
export * from "./idea_not_found.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e92112f8",
  null
  
)

export default component.exports