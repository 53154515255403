<template>
  <div id="discussion" class="bg-white rounded-xl border px-5 py-5 mb-8">
    <comment-input
      :post-id="postId"
      :board-slug="boardSlug"
      :current-user="currentUser"
      :discussion-users="discussionUsers"
      :added-new-comment="addedNewComment"
      @newComment="addNewComment($event)"
      :time-now="this.timeNow"
      id="comment-input-container"
      class="comment-input-container"
      :project-sso-only="projectSsoOnly"
      :custom-website-href="customWebsiteHref"
      :current-project-admin="currentProjectAdmin"
      :show-ai-message="showAiMessage"
      :subscription-valid="subscriptionValid"
      :comments-suggestions="commentsSuggestions"
    ></comment-input>

    <!-- <div
      class="bg-neutral-100 px-3 py-5 rounded-xl text-center text-neutral-800"
    >
      You need to
      <a
        href="#"
        @click.prevent="openRegModal()"
        class="font-semibold hover:no-underline hover:text-neutral-900"
        >log in</a
      >
      to comment
    </div> -->

    <ul class="" v-if="originalCommentsPinned.length > 0">
      <li
        is="comment"
        v-for="comment in originalCommentsPinned"
        :originalComment="comment"
        :current-user="currentUser"
        :current-project-admin="currentProjectAdmin"
        :post-id="postId"
        :board-slug="boardSlug"
        :discussion-users="discussionUsers"
        :key="comment.id"
        :showPin="showPin"
        @updatesComment="updateAllComments"
        :id="'show-comment-' + comment.id"
        :class="'show-comment-' + comment.id"
        :project-name="projectName"
      ></li>
    </ul>

    <comment-input
      class="mt-8 pb-8 reply-comment-input-container"
      v-if="currentUser && commentsCount > 5"
      :post-id="postId"
      :board-slug="boardSlug"
      :current-user="currentUser"
      :discussion-users="discussionUsers"
      :added-new-comment="addedNewComment"
      @newComment="addNewComment($event)"
      id="bottom-comment-input-container"
      :current-project-admin="currentProjectAdmin"
    ></comment-input>

    <div class="flex flex-col items-center mt-10 mb-6" v-if="comments.length === 0">
      <div>
        <div class="relative w-16 h-16 flex items-center justify-center mb-4 mx-auto">
          <div class="w-16 h-16 z-0 absolute bg-neutral-100 rounded-full top-0 left-0" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40px"
            height="40px"
            class="z-10 relative opacity-20"
          >
            <path d="M20 2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
          </svg>
        </div>
        <div class="text-neutral-700 text-center">
          <div class="text-sm">{{ $t("No comments yet") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentInput from "./comment_input.vue";
import Comment from "./comment.vue";

import EventBus from "event_bus";

export default {
  props: [
    "original-comments",
    "comments-count",
    "post-id",
    "current-user",
    "current-project-admin",
    "discussion-users",
    "board-slug",
    "empty-state-image-path",
    "time-now",
    "added-new-comment",
    "project-sso-only",
    "custom-website-href",
    "show-ai-message",
    "subscription-valid",
    "comments-suggestions",
    "project-name",
  ],
  data: function () {
    return {
      message: "",
      comments: this.originalComments,
    };
  },
  components: {
    CommentInput,
    Comment,
  },
  computed: {
    showPin() {
      return !(
        this.originalComments.filter((comment) => comment.pin == true).length >
        0
      );
    },
    originalCommentsPinned() {
      let comments = this.comments;
      comments = comments.sort((a, b) => b.pin - a.pin);
      console.log(comments);
      return comments;
    },
  },
  mounted() {},
  methods: {
    addNewComment: function (newComment) {
      this.comments.push(newComment);
      this.$nextTick(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      });
    },
    openRegModal: function () {
      EventBus.$emit("openRegModal");
    },
    updateAllComments: function (data) {
      this.comments = [...data];
    },
  },
};
</script>

<style scoped></style>
