<template>
  <div class="mt-5">
    <h2 class="text-neutral-800 text-sm font-medium">{{ $t("Merged ideas") }}</h2>

    <Collapser
      v-for="(post, index) in mposts"
      :key="post.id"
      :title="post.title"
      class="bg-neutral-100 rounded-lg px-3 py-2 mt-2"
      :class="'merged-post-'+post.id"
      :id="'merged-post-'+post.id"
    >
      <div class="mt-3" slot="content" id="merged-post-content">
        <p class="text-neutral-700 text-sm">{{ post.content }}</p>
        <footer class="mt-4 flex items-center justify-between">
          <span class="text-neutral-800 text-sm"
            >
            <span v-if="post.user && !post.hide_user_name">
            {{ $t("Posted by") }}
              <span class="font-semibold">{{ post.user.name }}</span> </span
            ><span v-else>anonymously</span></span
          >
          <a
            @click.prevent="deleteMPost(post, index)"
            href="#"
            class="flex items-center text-neutral-700 text-sm hover:no-underline hover:text-red-500"
            v-if="currentProjectAdmin"
          >
            <simple-svg
              :filepath="trashIcon"
              :width="'16px'"
              :height="'16px'"
              class="mr-1"
            />Delete
          </a>
        </footer>
      </div>
    </Collapser>
  </div>
</template>

<script>
import trashIcon from "images/trash.svg";
import Collapser from "../components/collapser";

export default {
  props: ["merged-posts", "post-id", "url", "current-project-admin"],
  data: function() {
    return {
      mposts: this.mergedPosts,
      trashIcon
    };
  },
  components: {
    trashIcon,
    Collapser
  },
  methods: {
    deleteMPost: function(post, index) {
      var confirmation = window.confirm(
        "Do you really want to delete this merged idea?"
      );

      if (confirmation) {
        Rails.ajax({
          url: this.url + "/" + post.id,
          type: "DELETE",
          processData: true,
          beforeSend: function() {
            return true;
          },
          success: data => {
            this.$delete(this.mposts, index);
            this.$message({
              showClose: true,
              message: "Merged idea was deleted",
              type: "success"
            });
          },
          error: (data, status) => {
            console.log(data);
          }
        });
      }
    }
  }
};
</script>

<style scoped></style>
