<template>
  <section>
    <div>
      <h3 class="text-2xl font-bold text-neutral-800 mb-8">
        Billing
      </h3>
      <!-- Billing Warnign Message -->
      <div
        v-for="warning in getWarningMessages"
        :key="warning"
        class="mb-6 p-4 bg-amber-50 border border-amber-200 rounded-xl flex gap-3"
      >
        <svg class="w-6 h-6 text-amber-500 flex-shrink-0" viewBox="0 0 24 24" fill="currentColor">
          <path d="M12 2L1 21h22L12 2zm0 3.45l8 14.1H4l8-14.1zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z"/>
        </svg>
        <div>
          <h3 class="font-semibold text-neutral-800 mb-1">Important</h3>
          <p class="text-gray-600">{{ warning }}</p>
        </div>
      </div>
      <!-- Plans-->
      <unlimited-plan
        v-if="
          isCodeActivated &&
          (currentPlan.name == 'unlimited' || currentPlan.name == 'lifetime')
        "
        :unlimited-plan="unlimited"
      />
      <div v-else class="grid grid-cols-1 xl:gap-y-3">
        <div
          class="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-x-6"
          :class="!showMenu ? 'xl:grid-cols-1' : 'xl:grid-cols-5'"
        >
          <div
            class="xl:col-span-2 lg:col-span-1 md:col-span-1 sm:col-span-1"
            v-if="showMenu"
          >
            <h3
              class="text-xl font-bold text-neutral-800 relative mr-2 mb-2 flex-1"
            >
              Menu
            </h3>
            <div class="menu-box flex flex-col h-48 p-7 lg:mb-4 md:mb-4">
              <div class="menu-item-container flex-col">
                <a
                  class="flex menu-item xl:justify-start lg:justify-center sm:justify-center mb-5"
                  :href="`${stripeCustomerPortalSession}/payment-methods`"
                >
                  <img
                    class="mr-2"
                    src="../../assets/images/payment-method.svg"
                    alt="payment-method"
                  />
                  <div class="flex items-center">Update payment method</div>
                </a>
                <a
                  class="flex menu-item xl:justify-start lg:justify-center sm:justify-center mb-5"
                  :href="`${stripeCustomerPortalSession}/customer/update`"
                >
                  <img
                    class="mr-2"
                    src="../../assets/images/payment-details.svg"
                    alt="payment-details"
                  /><span class="flex items-center"
                    >Update billing details</span
                  >
                </a>
                <a
                  class="flex menu-item xl:justify-start lg:justify-center sm:justify-center mb-5"
                  :href="`${stripeCustomerPortalSession}`"
                >
                  <img
                    class="mr-2"
                    src="../../assets/images/payment-history.svg"
                    alt="payment-history"
                  /><span class="flex items-center">View payment history</span>
                </a>
                <a
                  class="flex menu-item xl:justify-start lg:justify-center sm:justify-center"
                  :href="`${stripeCustomerPortalSession}/subscriptions/${this.getSubscriptionId}/cancel`"
                >
                  <img
                    class="mr-2"
                    src="../../assets/images/cross-mark.svg"
                    alt="cancel-subscription"
                  /><span class="flex items-center"
                    >Cancel my subscription</span
                  >
                </a>
              </div>
            </div>
          </div>
          <div
            class="lg:col-span-1 md:col-span-1 sm:col-span-1"
            :class="showMenu ? 'xl:col-span-3' : 'xl:col-span-1'"
          >
            <h3
              class="text-xl font-bold text-neutral-800 relative mr-2 mb-2 flex-1"
            >
              Overview
            </h3>
            <div
              class="current-plan-box rounded-xl border border-gray-200 p-6 bg-white"
              style="width: 100%"
              :style="{
                width: '100%',
                padding: currentPlan.stripe_payment_failed ? '20px' : '30px',
              }"
              :class="showMenu ? 'h-48' : ''"
            >
              <div class="text-sm font-medium text-gray-500 mb-1">CURRENT PLAN</div>
              <div class="text-2xl font-bold text-neutral-800 mb-4 capitalize">
                {{ getCurrentPlanName }}
              </div>
              <div v-if="currentPlan.paid_out_of_stripe" class="text-gray-600">
                For any requests and changes on your account, you can contact at
                <a href="mailto:sales@feedbear.com" class="text-primary-600 hover:text-primary-700">
                  sales@feedbear.com
                </a>
              </div>
              <div v-if="currentPlan.name == 'trial'" 
                :class="[
                  'rounded-xl p-4',
                  currentPlan.active_status 
                    ? 'bg-gray-50 text-gray-600'
                    : 'bg-red-50 text-red-600'
                ]"
              >
                <span v-if="currentPlan.active_status">
                  Trial ends on: {{ nextPaymentDate }}
                </span>
                <span v-else>
                  <strong>ATTENTION!</strong> Your trial has expired on {{ nextPaymentDate.trim() }}
                  {{ !currentPlan.active_status ? ". Upgrade to re-activate your project." : "" }}
                </span>
              </div>

              <div v-if="showMenu" class="mt-4 text-gray-600">
                <span class="font-medium">Price:</span>
                {{
                  currentPlan.is_annual_billing_period
                    ? `$${currentSubscriptionPrice}/year`
                    : `$${currentSubscriptionPrice}/month`
                }}
              </div>
              <div v-if="showMenu" class="mt-4">
                <button
                  v-if="currentPlan.stripe_payment_failed"
                  class="w-full p-4 bg-red-50 text-red-600 rounded-xl"
                >
                  <strong>ATTENTION!</strong> We haven't identified a payment for your account, 
                  update your billing details to reactivate your project
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!currentPlan.paid_out_of_stripe"
          class="flex flex-col mt-4 sm:col-span-2 lg:col-span-1 xl:col-span-3 mb-3"
        >
          <!-- Header and Toggle -->
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-xl font-bold text-neutral-800">
              Plans
            </h2>
            <div class="billing-toggle flex items-center gap-3 p-2">
              <label
                for="toggle"
                class="text-sm font-medium transition-colors"
                :class="isAnnualPlan ? 'text-gray-400' : 'text-neutral-800'"
              >Monthly</label>
              <div class="relative">
                <input
                  v-model="isAnnualPlan"
                  type="checkbox"
                  name="toggle"
                  id="toggle"
                  class="toggle-checkbox sr-only"
                />
                <label
                  for="toggle"
                  class="flex h-6 w-11 items-center rounded-full bg-gray-200 p-1 cursor-pointer transition-colors duration-300"
                  :class="{ 'bg-[#F84239]': isAnnualPlan }"
                >
                  <div 
                    class="h-4 w-4 rounded-full bg-white transition-transform duration-300"
                    :class="{ 'translate-x-5': isAnnualPlan }"
                  ></div>
                </label>
              </div>
              <label
                for="toggle"
                class="text-sm font-medium transition-colors"
                :class="isAnnualPlan ? 'text-neutral-800' : 'text-gray-400'"
              >Annual</label>
            </div>
          </div>

          <!-- Plan Cards Grid -->
          <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-6">
            <!-- Lite Plan -->
            <div
              :class="[
                'plan-card rounded-xl border transition-all duration-300',
                isLiteApplicable 
                  ? '' 
                  : 'opacity-60 bg-gray-50'
              ]"
            >
              <div class="p-6">
                <h3 class="text-xl font-bold text-neutral-800 mb-2">Lite</h3>
                <div class="flex items-baseline mb-4">
                  <span class="text-3xl font-bold text-neutral-800">
                    ${{ isAnnualPlan ? litePlanData.price.annually : litePlanData.price.monthly }}
                  </span>
                  <span class="text-gray-500 ml-2">/month</span>
                </div>
                <div class="mb-6">
                  <button
                    v-if="currentPlan.name == litePlanData.name"
                    class="w-full py-3 px-6 rounded-xl bg-gray-100 text-gray-400 font-semibold cursor-not-allowed"
                    disabled
                  >
                    Current Plan
                  </button>
                  <button
                    v-else-if="currentPlan.order < litePlanData.order"
                    class="w-full py-3 px-6 rounded-xl bg-[#F84239] text-white font-semibold hover:brightness-95 transition-colors duration-300 flex items-center justify-center gap-2"
                    :disabled="!isLiteApplicable"
                    @click="handlePlanUpgradeSelection('lite')"
                  >
                    <span>Upgrade Now</span>
                    <loader v-if="loaderActive.lite_yearly || loaderActive.lite_monthly" border-color="#fff" />
                  </button>
                  <button
                    v-else
                    class="w-full py-3 px-6 rounded-xl bg-gray-600 text-white font-semibold hover:bg-gray-700 transition-colors duration-300 flex items-center justify-center gap-2"
                    @click.prevent="handlePlanDowngradeSelection('lite')"
                  >
                    <span>Downgrade</span>
                    <loader v-if="loaderActive.lite_yearly || loaderActive.lite_monthly" border-color="#fff" />
                  </button>
                </div>
                <ul class="space-y-3">
                  <li 
                    v-for="feature in litePlanData.features" 
                    :key="feature"
                    class="flex items-start gap-3 text-sm"
                  >
                    <svg class="w-5 h-5 text-green-500 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                    </svg>
                    <span class="text-gray-700">{{ feature }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- Startup Plan -->
            <div
              v-if="currentPlan && currentPlan.name == 'startup'"
              :class="[
                'plan-card rounded-xl border transition-all duration-300',
                isStartupApplicable 
                  ? '' 
                  : 'opacity-60 bg-gray-50'
              ]"
            >
              <div class="p-6">
                <h3 class="text-xl font-bold text-neutral-800 mb-2 capitalize">
                  Startup
                </h3>
                <div class="flex items-baseline mb-4">
                  <span class="text-3xl font-bold text-neutral-800">
                    ${{ isAnnualPlan ? startupPlanData.price.annually : startupPlanData.price.monthly }}
                  </span>
                  <span class="text-gray-500 ml-2">/month</span>
                </div>
                <div class="mb-6">
                  <button
                    v-if="currentPlan.name == startupPlanData.name"
                    class="w-full py-3 px-6 rounded-xl bg-gray-100 text-gray-400 font-semibold cursor-not-allowed"
                    disabled
                  >
                    Current Plan
                  </button>
                  <button
                    v-else-if="currentPlan.order < startupPlanData.order"
                    class="w-full py-3 px-6 rounded-xl bg-[#F84239] text-white font-semibold hover:brightness-95 transition-colors duration-300 flex items-center justify-center gap-2"
                    :disabled="!isStartupApplicable"
                    @click="handlePlanUpgradeSelection('startup')"
                  >
                    <span>Upgrade Now</span>
                    <loader v-if="loaderActive.startup_yearly || loaderActive.startup_monthly" border-color="#fff" />
                  </button>
                  <button
                    v-else
                    class="w-full py-3 px-6 rounded-xl bg-gray-600 text-white font-semibold hover:bg-gray-700 transition-colors duration-300 flex items-center justify-center gap-2"
                    @click.prevent="handlePlanDowngradeSelection('startup')"
                  >
                    <span>Downgrade</span>
                    <loader v-if="loaderActive.startup_yearly || loaderActive.startup_monthly" border-color="#fff" />
                  </button>
                </div>
                <ul class="space-y-3">
                  <li 
                    v-for="feature in startupPlanData.features" 
                    :key="feature"
                    class="flex items-start gap-3 text-sm"
                  >
                    <svg class="w-5 h-5 text-green-500 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                    </svg>
                    <span class="text-gray-700">{{ feature }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- Startup 2023 Plan -->
            <div
              v-if="!currentPlan || currentPlan.name != 'startup'"
              :class="[
                'plan-card rounded-xl border transition-all duration-300',
                isStartupApplicable 
                  ? '' 
                  : 'opacity-60 bg-gray-50'
              ]"
            >
              <div class="p-6">
                <h3 class="text-xl font-bold text-neutral-800 mb-2 capitalize">
                  Startup
                </h3>
                <div class="flex items-baseline mb-4">
                  <span class="text-3xl font-bold text-neutral-800">
                    ${{ isAnnualPlan ? startup202304PlanData.price.annually : startup202304PlanData.price.monthly }}
                  </span>
                  <span class="text-gray-500 ml-2">/month</span>
                </div>
                <div class="mb-6">
                  <button
                    v-if="currentPlan.name == startup202304PlanData.name"
                    class="w-full py-3 px-6 rounded-xl bg-gray-100 text-gray-400 font-semibold cursor-not-allowed"
                    disabled
                  >
                    Current Plan
                  </button>
                  <button
                    v-else-if="currentPlan.order < startup202304PlanData.order"
                    class="w-full py-3 px-6 rounded-xl bg-[#F84239] text-white font-semibold hover:brightness-95 transition-colors duration-300 flex items-center justify-center gap-2"
                    :disabled="!isStartupApplicable"
                    @click="handlePlanUpgradeSelection('startup_202304')"
                  >
                    <span>Upgrade Now</span>
                    <loader v-if="loaderActive.startup_yearly || loaderActive.startup_monthly" border-color="#fff" />
                  </button>
                  <button
                    v-else
                    class="w-full py-3 px-6 rounded-xl bg-gray-600 text-white font-semibold hover:bg-gray-700 transition-colors duration-300 flex items-center justify-center gap-2"
                    @click.prevent="handlePlanDowngradeSelection('startup_202304')"
                  >
                    <span>Downgrade</span>
                    <loader v-if="loaderActive.startup_yearly || loaderActive.startup_monthly" border-color="#fff" />
                  </button>
                </div>
                <ul class="space-y-3">
                  <li 
                    v-for="feature in startup202304PlanData.features" 
                    :key="feature"
                    class="flex items-start gap-3 text-sm"
                  >
                    <svg class="w-5 h-5 text-green-500 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                    </svg>
                    <span class="text-gray-700">{{ feature }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- Business Plan -->
            <div
              :class="[
                'plan-card rounded-xl border transition-all duration-300',
                isBusinessApplicable 
                  ? '' 
                  : 'opacity-60 bg-gray-50'
              ]"
            >
              <div class="p-6">
                <h3 class="text-xl font-bold text-neutral-800 mb-2">
                  Business
                </h3>
                <div class="flex items-baseline mb-4">
                  <span class="text-3xl font-bold text-neutral-800">
                    ${{ isAnnualPlan ? businessPlanData.price.annually : businessPlanData.price.monthly }}
                  </span>
                  <span class="text-gray-500 ml-2">/month</span>
                </div>
                <div class="mb-6">
                  <button
                    v-if="currentPlan.name == businessPlanData.name"
                    class="w-full py-3 px-6 rounded-xl bg-gray-100 text-gray-400 font-semibold cursor-not-allowed"
                    disabled
                  >
                    Current Plan
                  </button>
                  <button
                    v-else-if="currentPlan.order < businessPlanData.order"
                    class="w-full py-3 px-6 rounded-xl bg-[#F84239] text-white font-semibold hover:brightness-95 transition-colors duration-300 flex items-center justify-center gap-2"
                    :disabled="!isBusinessApplicable"
                    @click="handlePlanUpgradeSelection('business')"
                  >
                    <span>Upgrade Now</span>
                    <loader v-if="loaderActive.business_monthly || loaderActive.business_yearly" border-color="#fff" />
                  </button>
                  <button
                    v-else
                    class="w-full py-3 px-6 rounded-xl bg-gray-600 text-white font-semibold hover:bg-gray-700 transition-colors duration-300 flex items-center justify-center gap-2"
                    @click.prevent="handlePlanDowngradeSelection('business')"
                  >
                    <span>Downgrade</span>
                    <loader v-if="loaderActive.business_monthly || loaderActive.business_yearly" border-color="#fff" />
                  </button>
                </div>
                <ul class="space-y-3">
                  <li 
                    v-for="feature in businessPlanData.features" 
                    :key="feature"
                    class="flex items-start gap-3 text-sm"
                  >
                    <svg class="w-5 h-5 text-green-500 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                    </svg>
                    <span class="text-gray-700">{{ feature }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- Enterprise Contact Card -->
          <div class="mt-8 rounded-xl border bg-gray-50 p-8">
            <div class="flex flex-col md:flex-row items-center justify-between gap-6">
              <div>
                <h2 class="text-xl font-bold text-neutral-800 mb-2">
                  Need more team members or custom features?
                </h2>
                <p class="text-gray-600">Contact us for our Enterprise plan starting from $299 per month</p>
              </div>
              <a
                href="https://www.feedbear.com/contact-us"
                target="_blank"
                class="px-8 py-3 rounded-xl bg-gray-900 text-white w-full md:w-fit text-center font-semibold hover:bg-gray-800 hover:no-underline transition-colors duration-300 whitespace-nowrap"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      :title="changePlanDialog ? 'Important!' : 'Changes needed!'"
      v-model="dialogVisible"
      :isBilling="true"
    >
      <div class="message-dialog px-4">
        <div v-if="!changePlanDialog">
          <h2 class="dialog-heading mb-4 px-5 text-base">
            Before downgrading your plan please change the following:
          </h2>

          <ul class="list-disc list-outside px-12">
            <li
              class="mb-2"
              v-for="message in getComparisonMessages"
              :key="message"
            >
              <span class="text-base" style="color: #575757">{{
                message
              }}</span>
            </li>
          </ul>

          <div class="flex justify-center items-center mt-4 mb-7 space-x-2">
            <button
              class="rounded-sm btn-main bg-red-500 border-red-500 hover:border-red-500 hover:text-red-500"
              type="primary"
              @click="dialogVisible = false"
            >
              Ok, I understand
            </button>
          </div>
        </div>
        <div v-else>
          <div class="text-base font-normal text-gray-700">
            <p class="mb-3">
              If you downgrade your plan features and limits that are only
              available to higher tiers will be automatically updated.
            </p>
            <p>
              We recommend checking these details before downgrading your plan
              and making sure they match the features and limits available on
              your new chosen plan.
            </p>
          </div>
          <div class="flex justify-center items-center mt-4 mb-7 space-x-2">
            <button
              class="rounded-sm btn-main bg-red-500 border-red-500 hover:border-red-500 hover:text-red-500"
              type="primary"
              @click="
                dialogVisible = false;
                changePlanDialog = false;
                loaderActive['change_plan'] = true;
                updatePlan();
              "
            >
              Ok, I understand
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  </section>
</template>
<script>
import Dialog from "../components/dialog";
import UnlimitedPlan from "../components/unlimited_plan";
import loader from "./spinner";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialogVisible: false,
      changePlanDialog: false,
      isAnnualPlan: false,
      showCorporate: false,
      stripeCustomerPortalSession: "",
      planUpgradeOrDowngradeMessages: [],
      allAvailablePlans: [
        "lite",
        "startup",
        "business",
        "business_202306",
        "professional",
        "stripe_monthly",
        "stripe_yearly",
        "corporate_50",
        "corporate_100",
        "corporate",
        "startup_202304",
      ],
      litePlanData: {
        name: "lite",
        price: {
          monthly: "19",
          annually: "15",
        },
        order: 1,
        features: [
          "1 team member",
          "1 board",
          "Unlimited ideas",
          "Unlimited users",
        ],
      },
      startupPlanData: {
        name: "startup",
        price: {
          monthly: "29",
          annually: "24",
        },
        order: 2,
        features: [
          "3 team members",
          "Unlimited boards",
          "Custom domain",
          "Integrations with Intercom, Slack, Trello, Jira and Zapier",
        ],
      },
      startup202304PlanData: {
        name: "startup_202304",
        price: {
          monthly: "49",
          annually: "40",
        },
        order: 2,
        features: [
          "3 team members",
          "Unlimited boards",
          "Custom domain",
          "Integrations with Intercom, Slack, Trello, Jira and Zapier",
        ],
      },
      businessPlanData: {
        name: "business",
        price: {
          monthly: "99",
          annually: "82",
        },
        order: 3,
        features: [
          "10 team members",
          "Single Sign On (SSO)",
          "Private projects",
          "Remove FeedBear branding",
        ],
      },
      business202306PlanData: {
        name: "business",
        price: {
          monthly: "199",
          annually: "165",
        },
        order: 3,
        features: [
          "10 team members",
          "Single Sign On (SSO)",
          "Private projects",
          "Remove FeedBear branding",
        ],
      },
      professionalPlanData: {
        name: "professional",
        price: {
          monthly: "199",
          annually: "1,990",
        },
        order: 3,
        features: [
          "20 team members",
          "Jira integration",
          'Remove "Powered by FeedBear"',
        ],
      },
      enterprisePlanData: {
        name: "enterprise",
        price: {
          monthly: "299",
          annually: "249",
        }
      },
      customPlanData: {
        name: "Custom",
        features: [
          "Unlimited team members",
          "Priority support over Slack, Phone, Email",
          "Custom Contracts, NDA and SLA",
          "Custom DPAs",
          "Custom integrations",
          "Pay by bank transfer",
          "Security audit",
        ],
      },
      corporatePlan: [
        {
          name: "corporate_50",
          price: {
            monthly: "299",
            annually: "2,990",
          },
          order: 4,
          features: ["50 team members"],
        },
        {
          name: "corporate_100",
          price: {
            monthly: "499",
            annually: "4,990",
          },
          order: 5,
          features: ["100 team members"],
        },
      ],
      loaderActive: {
        change_plan: false,
        startup_monthly: false,
        startup_yearly: false,
        business_monthly: false,
        business_yearly: false,
        professional_monthly: false,
        professional_yearly: false,
        corporate_50_monthly: false,
        corporate_50_yearly: false,
        corporate_100_monthly: false,
        corporate_100_yearly: false,
      },
      unlimited: {},
    };
  },
  props: {
    currentPlan: Object,
    currentLoggedInUser: Object,
    isCodeActivated: Boolean,
  },
  components: {
    Dialog,
    loader,
    UnlimitedPlan,
  },
  mounted() {
    this.isAnnualPlan =
      this.getCurrentProjectPlan &&
      this.getCurrentProjectPlan.is_annual_billing_period;
    if (this.showMenu) {
      Rails.ajax({
        url: "/customer_portal_sessions",
        type: "POST",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.stripeCustomerPortalSession = data.portal_url;
        },
        error: (data, status) => {
          this.error = data.message;
        },
      });
    }
    Rails.ajax({
      url: "/get_appsumo_plan",
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        this.unlimited = data.plan;
      },
      error: (data, status) => {
        console.log(data);
      },
    });
  },
  computed: {
    ...mapGetters([
      "getCurrentProjectPlan",
      "getWarningMessages",
      "getPlanFeatures",
      "getSubscriptionId",
      "getStripePriceKeys",
      "getStripeSubscription",
    ]),
    nextPaymentDate() {
      var format = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (this.currentPlan.valid_until) {
        var nextPayment = new Date(
          this.currentPlan.valid_until
        ).toLocaleDateString("en-us", format);

        return new Date(this.currentPlan.valid_until) < new Date()
          ? false
          : nextPayment;
      } else {
        return new Date(this.currentPlan.trial_valid_until).toLocaleDateString(
          "en-us",
          format
        );
      }
    },
    isLiteApplicable() {
      if (this.currentPlan.name != "lite") {
        return (
          this.currentPlan.team_count <=
          this.getPlanFeatures[this.litePlanData.name]?.team &&
          this.currentPlan.boards_count <= 1
        );
      } else return true;
    },
    isStartupApplicable() {
      if (this.currentPlan.name != "startup" && this.currentPlan.name != "startup_202304") {
        return (
          this.currentPlan.team_count <=
            this.getPlanFeatures[this.startup202304PlanData.name].team &&
          !this.currentPlan.sso_enabled &&
          !this.currentPlan.privacy_enabled
        );
      } else return true;
    },
    isBusinessApplicable() {
      if (this.currentPlan.name != "business") {
        return (
          this.currentPlan.team_count <=
          this.getPlanFeatures[this.businessPlanData.name].team
        );
      } else return true;
    },
    getComparisonMessages() {
      return this.$store.state.planMessages;
    },
    isTrialExpired() {
      if (this.currentPlan.trial_valid_until) {
        return new Date(this.currentPlan.trial_valid_until) < new Date();
      } else return false;
    },
    showMenu() {
      return (
        this.allAvailablePlans.includes(this.currentPlan.name) &&
        !this.currentPlan.paid_out_of_stripe
      );
    },
    currentSubscriptionPrice() {
      if (this.getStripeSubscription) {
        if (this.getStripeSubscription.discount)
          return this.discountedPrice(
            this.getStripeSubscription.discount.coupon,
            this.getStripeSubscription["plan"]["amount_decimal"].slice(0, -2)
          );
        else
          return this.getStripeSubscription["plan"]["amount_decimal"].slice(
            0,
            -2
          );
      } else {
        return this.currentPlan.price;
      }
    },
    getCurrentPlanName() {
      return this.currentPlan.paid_out_of_stripe
        ? "Custom Corporate Plan Paid By Bank Transfer"
        : this.currentPlan["name"]
            .replace("_", " ")
            .replace("202304", "")
            .replace("202306", "");
    },
  },
  methods: {
    handleTrialUpdate(toPlan) {
      this.$emit("plan-selection", toPlan);
    },
    changePlan() {
      if (
        this.currentPlan.order > 1 &&
        (this.currentPlan.sso_enabled ||
          this.currentPlan.privacy_enabled ||
          this.currentPlan.team_count > 3)
      ) {
        this.changePlanDialog = true;
        this.dialogVisible = true;
      } else {
        this.loaderActive["change_plan"] = true;
        this.updatePlan();
      }
    },
    updatePlan() {
      Rails.ajax({
        url: "/customer_portal_sessions",
        type: "POST",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          window.location.replace(
            `${data.portal_url}/subscriptions/${this.getSubscriptionId}/update`
          );
          this.loaderActive["change_plan"] = false;
        },
        error: (data, status) => {
          this.error = data.message;
        },
      });
    },
    async validatePlan(plan_name) {
      if (this.changePlanDialog) this.changePlanDialog = false;
      var plan = plan_name.includes("monthly")
        ? plan_name.replace("_monthly", "")
        : plan_name.replace("_yearly", "");
      await this.$store.dispatch(
        "comparePlan",
        this.allAvailablePlans[this.allAvailablePlans.indexOf(plan)]
      );
      setTimeout(() => {
        if (this.getComparisonMessages.length > 0) this.dialogVisible = true;
        else {
          this.currentPlan.name == "lifetime" ||
          this.currentPlan.name == "unlimited"
            ? this.handleTrialUpdate(plan_name)
            : this.toStripePlan(plan_name);
        }
      }, 300);
    },
    toStripePlan(planName) {
      this.loaderActive[planName] = true;
      Rails.ajax({
        url: "/customer_portal_sessions",
        type: "POST",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          window.location.replace(
            `${data.portal_url}/subscriptions/${this.getSubscriptionId}/preview/${this.getStripePriceKeys[planName]}`
          );
          this.loaderActive[planName] = false;
        },
        error: (data, status) => {
          this.error = data.message;
        },
      });
    },
    handlePlanUpgradeSelection(plan) {
      this.isAnnualPlan
        ? this.allAvailablePlans.includes(this.currentPlan.name)
          ? this.toStripePlan(plan + "_yearly")
          : this.handleTrialUpdate(plan + "_yearly")
        : this.allAvailablePlans.includes(this.currentPlan.name)
        ? this.toStripePlan(plan + "_monthly")
        : this.handleTrialUpdate(plan + "_monthly");
    },
    handlePlanDowngradeSelection(plan) {
      this.isAnnualPlan
        ? this.validatePlan(plan + "_yearly")
        : this.validatePlan(plan + "_monthly");
    },
    discountedPrice(coupon, price) {
      if (coupon.percent_off) return price * ((100 - coupon.percent_off) / 100);
      else if (coupon.amount_off) return price - coupon.amount_off / 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert_box {
  width: 100%;
  left: 40px;
  top: 71px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 3px;
}
.alert_border {
  width: 5px;
  left: 40px;
  top: 71px;
  background: #eaaa06;
  border-radius: 3px 0px 0px 3px;
}
.alert_vector {
  padding: 25px 28px 25px 32px;
}
.current-plan-box {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 6px;
  .plan-title {
    color: #b0b0b0;
    font-size: 14px;
    line-height: 19px;
  }
  .plan-name {
    font-size: 24px;
    line-height: 28px;
    color: #525252;
    margin-bottom: 12px;
  }
  .plan-name::first-letter {
    text-transform: capitalize;
  }
  .next-payment {
    font-size: 14px;
    line-height: 16px;
    color: #979797;
  }
  .change-plan-btn {
    font-size: 14px;
    cursor: pointer;
    color: #f84339;
  }
  .color-next-payment {
    font-size: 14px;
    line-height: 16px;
    color: red;
  }
}
.menu-box {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 6px;
  .menu-item-container {
    .menu-item {
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      color: #575757;
    }
  }
  .cancel-subscription {
    padding: 22px 30px;
    border-top: 1px solid #dddddd;
    &--btn {
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      color: #575757;
    }
  }
}
.toggle-btn {
  .toggle-label {
    font-size: 14px;
    line-height: 16px;
  }
  .switch-bg {
    background-color: #e8e8e8;
  }
  .toggle-checkbox {
    border-color: #ffffff;
    background-color: #ffffff;
    top: 2px;
  }
  .toggle-checkbox:checked {
    right: -2px;
  }
}
.plan-container {
  padding: 30px 0px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 6px;
  .plan-heading {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #525252;
    margin-bottom: 7px;
  }
  .plan-heading::first-letter {
    text-transform: capitalize;
  }
  .plan-price {
    white-space: nowrap;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #979797;
    margin-bottom: 23px;
  }
  .enterprise-plan-price {
    white-space: nowrap;
    font-size: 16px;
    line-height: 26px;
    color: #979797;
  }
  .plan-feature-container {
    padding: 0px 20px;
    .plan-feature {
      justify-content: flex-start;
      margin-bottom: 7px;
      font-size: 14px;
      line-height: 130%;
      color: #525252;
      svg {
        min-width: 16px;
      }
    }
  }
  .plan-selection-btn {
    padding: 14px 31px;
    width: 100%;
    height: 45px;
    border-radius: 0.5rem;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 0px auto;
    .plan-btn-span-text {
      white-space: nowrap;
    }
  }
}
.message-dialog {
  .dialog-heading {
    white-space: nowrap;
    line-height: 140%;
    color: #575757;
  }
}
.disable-plan {
  opacity: 0.7;
  background: #f9f9f9;
}
.corporate-plan {
  min-width: 253px;
  height: 230px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media only screen and (min-width: 380px) and (max-width: 640px) {
  .current-plan-box {
    align-items: center;
  }
  .menu-item {
    justify-content: center;
  }
  .cancel-subscription {
    justify-content: center;
  }
}
.trial_alert {
  background: #fee2e2;
  border-radius: 3px;
}

@media screen and (min-width: 768px) {
  .md\:max-w-fit {
    max-width: fit-content;
  }
}

.ml-auto {
  margin-left: auto;
}

.\!py-5 {
  padding-top: 1.25rem!important;
  padding-bottom: 1.25rem!important;
}

.plan-card {
  @apply border-gray-200;
  
  &:hover:not(.opacity-60) {
    transform: translateY(-2px);
  }
}

.billing-toggle {
  label {
    user-select: none;
  }
}
</style>
